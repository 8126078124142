import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import MonthlyInvoiceBlock from "./MonthlyInvoiceBlock";
import { showError } from "../../../services/toast-service";
import { useTranslation } from "react-i18next";
import Loader from "../../loader/loader";
import { getCustomerInvoices } from "../../../services/api-declaration";

const YearsTab = ({ customerId, years }) => {
  const { t } = useTranslation();
  const [invoicesByYear, setInvoicesByYear] = useState({});
  const [loadingYears, setLoadingYears] = useState({});

  const handleTabSelect = async (year) => {
    if (invoicesByYear[year]) return;
    setLoadingYears((prev) => ({ ...prev, [year]: true }));
    try {
      const response = await getCustomerInvoices(customerId, { year });
      setInvoicesByYear((prev) => ({ ...prev, [year]: response.data.data }));
    } catch (error) {
      console.log("Error fetching invoices for year:", error);
      showError(error.response?.data?.message || "Failed to fetch invoices");
    } finally {
      setLoadingYears((prev) => ({ ...prev, [year]: false }));
    }
  };

  useEffect(() => {
    handleTabSelect(years[0]);
    // eslint-disable-next-line
  }, [years]);

  return (
    <Tabs
      defaultActiveKey={years[0]}
      id="year-tabs"
      className="mt-4 mb-2"
      onSelect={handleTabSelect}
    >
      {years.map((year) => (
        <Tab key={year} eventKey={year} title={year}>
          {loadingYears[year] ? (
            <Loader calcHeight={500} />
          ) : invoicesByYear[year]?.length > 0 ? (
            <MonthlyInvoiceBlock invoices={invoicesByYear[year]} />
          ) : (
            <div className="mt-5 mb-5 text-center fs-4">
              {t("invoice.noInvoicesForYear")}
            </div>
          )}
        </Tab>
      ))}
    </Tabs>
  );
};

export default YearsTab;
