import { Modal } from "react-bootstrap";
import { ModalHead } from "../../common-styling";
import { DISBALE_LS_ACTIONS } from "../../strings";
import AssociatedIntegrationActionButton from "./associated-integration-action-button";

const DisableLeadServiceConfirmationDialog = ({
  show,
  title,
  dialogType,
  toggleDialog,
  renderDialogMessage,
  dialogResponseAction
}) => {
  const { SYNC_NOW_AND_DISABLE, DISABLE_NOW_AND_SYNC_LATER } =
    DISBALE_LS_ACTIONS;
  const handleClick = (action, btnEventType) => {
    dialogResponseAction(action, btnEventType);
    toggleDialog();
  };
  const activityButtons = [
    {
      action: true,
      text: "disableLeadServiceModal.syncNowAndDisable",
      variant: "success",
      handleClick: handleClick,
      eventType: SYNC_NOW_AND_DISABLE
    },
    {
      action: true,
      text: "disableLeadServiceModal.disableNowAndSyncLater",
      variant: "primary",
      handleClick: handleClick,
      eventType: DISABLE_NOW_AND_SYNC_LATER
    },
    {
      action: false,
      text: "common.cancel",
      variant: "secondary",
      handleClick: handleClick,
      eventType: ""
    }
  ];

  return (
    <Modal show={show} onHide={toggleDialog} backdrop="static">
      <ModalHead
        className={`fw-bold ${dialogType === "warning" ? "" : "text-light"}`}
        bg={dialogType}
      >
        <Modal.Title>{title}</Modal.Title>
      </ModalHead>
      <Modal.Body>{renderDialogMessage}</Modal.Body>
      <Modal.Footer className={`border-${dialogType}`}>
        {activityButtons.map((item, buttonIndex) => (
          <AssociatedIntegrationActionButton
            key={buttonIndex}
            text={item.text}
            action={item.action}
            variant={item.variant}
            eventType={item.eventType}
            handleClick={item.handleClick}
          />
        ))}
      </Modal.Footer>
    </Modal>
  );
};
export default DisableLeadServiceConfirmationDialog;
