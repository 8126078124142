import YearsTab from "./YearsTab";

const InvoiceLists = ({ customerId, years }) => {
  return (
    <div>
      <YearsTab customerId={customerId} years={years} />
    </div>
  );
};

export default InvoiceLists;
