export const FUNNEL_QA_ACTIONS = {
  DUPLICATE: "DUPLICATE",
  DELETE: "DELETE",
  COPY: "COPY"
};
export const QUESTION_TYPE = {
  ICONS: "ICONS",
  TEXTONLY: "TEXTONLY",
  FREE_TEXT_FIELD: "FREE_TEXT_FIELD",
  SLIDER: "SLIDER"
};

export const FUNNEL_TYPE_FILTER = {
  ALL: "ALL",
  LEAD: "LEAD",
  EMPLOYEE: "EMPLOYEE"
};

export const FUNNEL_BUILD_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL"
};

export const INTEGRATION_TYPE = {
  GOOGLESHEET: "GOOGLE-SHEET",
  ZAPIER: "ZAPIER",
  SLACK: "SLACK",
  CONTACT_CLOUD: "CONTACT_CLOUD",
  EMAIL: "EMAIL"
};

export const DATE_FORMAT = "YYYY-MM-DD";

export const DATE_REVERSE_FORMAT = "DD-MM-YYYY";

export const DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm";

export const DATE_TIME_COMPLETE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const DISPLAY_FUNNEL = {
  ARCHIVED_ONES: "ARCHIVED_ONES",
  NON_ARCHIVED_ONES: "NON_ARCHIVED_ONES"
};

export const S3_FOLDER_ENUM = {
  QA: "QA",
  ASSET: "ASSET",
  GALLERY: "GALLERY"
};

export const LEAD_LOGS_STATUS_TYPE = {
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  RETRY: "RETRY"
};

export const FUNNEL_PAGES = {
  FUNNEL_FORM: "FUNNEL_FORM",
  LANDING_PAGE: "LANDING_PAGE",
  FUNNEL_FILTERS: "FUNNEL_FILTERS",
  INTEGRATION_FORM: "INTEGRATION_FORM",
  FUNNEL_INSIGHT: "FUNNEL_INSIGHT",
  COCKPIT_FORM: "COCKPIT_FORM",
  FACEBOOK_FORM: "FACEBOOK_FORM",
  AD_COPY: "AD_COPY",
  AD_DUPLICATOR: "AD_DUPLICATOR",
  LS_OVERVIEW: "LS_OVERVIEW"
};

export const FORM_SOURCE_TYPE = {
  FB: "FB",
  CP: "CP",
  CP_FB: "CP_FB"
};

export const LEAD_SOURCE = {
  CP: "CP",
  FB_CP: "FB_CP",
  FB_AD: "FB_AD",
  HOMEPAGE: "homepage",
  UNCATEGORISED: "uncategorised"
};

export const MODAL_TYPE = {
  VIEW: "VIEW",
  CREATE: "CREATE"
};

export const FOOTER_FOREGROUND = {
  BLACK: "#000000",
  WHITE: "#ffffff",
  SHADOWED: "shadowed"
};

export const ACTIVITY_TYPES = {
  CALL: "CALL",
  SMS: "SMS",
  EMAIL: "EMAIL",
  RESET: "RESET",
  SYNC_MANUALLY: "SYNC_MANUALLY",
  PARTIAL_SYNC_MANUALLY: "PARTIAL_SYNC_MANUALLY"
};

export const ACTIVITY_RESULT_TYPES = {
  NUMBER_WRONG: "NUMBER_WRONG",
  NAME_WRONG: "NAME_WRONG",
  COMPLETED: "COMPLETED",
  CALL: "CALL",
  QUALIFIED: "QUALIFIED",
  NOT_SUITABLE: "NOT_SUITABLE",
  NOT_REACHED: "NOT_REACHED",
  RESET_SUCCESS: "RESET_SUCCESS",
  SYNCED_MANUALLY: "SYNCED_MANUALLY",
  CONTACT_LATER: "CONTACT_LATER"
};

export const LEAD_INTERACTION_STATUS = {
  INITIATED: "INITIATED",
  NOT_INITIATED: "NOT_INITIATED",
  HANDLED: "HANDLED"
};

export const FB_FORM_MODE = {
  EDITABLE: "EDITABLE",
  READONLY: "READONLY",
  ADD_NEW: "ADD_NEW"
};

export const SELECT_TAB = {
  SEARCH: "SEARCH",
  CUSTOM: "CUSTOM"
};

export const USER_ROLES = {
  ADMIN: "ADMIN",
  AGENT: "AGENT"
};

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  IN_ACTIVE: "IN-ACTIVE"
};

export const TONALITY_TYPES = {
  SERIOUS: "Seriös",
  CASUAL: "Lässig",
  HUMOROUS: "Humorvoll",
  INSPIRING: "Inspirierend",
  ROMANTIC: "Romantisch",
  FACTUAL: "Sachlich",
  NOSTALGIC: "Nostalgisch",
  PROVOCATIVE: "Provokativ",
  LUXURIOUS: "Luxuriös",
  EDUCATIONAL: "Pädagogisch"
};

export const EMOJI_SELCECT_LABELS = {
  Ja: "Ja",
  Nein: "Nein"
};

export const SEND_DATA = {
  NEVER: "NEVER",
  IMMEDIATELY: "IMMEDIATELY",
  AFTER_CONFIRMATION: "AFTER_CONFIRMATION"
};

export const LEAD_AGENT_TIMESTAMP_UPDATE_DELAY = 1 * 1000 * 60;

export const MULTI_FACTOR_TYPES = {
  AUTHENTICATOR: "AUTHENTICATOR",
  EMAIL: "EMAIL"
};

export const PHONE_NUMBER_RADIO = {
  STANDARD: "STANDARD",
  LIGHT: "LIGHT",
  VERIFY: "VERIFY"
};

export const FB_CONVERSION_API_OPTS = {
  WAREHOUSES: { name: "A&M Data Warehouses Pixel", _id: "2236084886702651" },
  GMBHS: {
    name: "A&M Unternehmerberatung GmbHs Pixel",
    _id: "1048497165333604"
  }
};

export const ICON_TYPE = {
  ALREADY_INITIATED: "ALREADY_INITIATED",
  ALREADY_QUALIFIED: "ALREADY_QUALIFIED",
  NOT_AVAILABLE_NOW: "NOT_AVAILABLE_NOW",
  ALREADY_ASSIGNED: "ALREADY_ASSIGNED",
  TARGET_BLANK: "TARGET_BLANK",
  ARCHIVED: "ARCHIVED",
  NO_LEADS: "NO_LEADS",
  CONTACT_LATER: "CONTACT_LATER"
};

export const SETTINGS = {
  PROFILE: "PROFILE",
  SECURITY: "SECURITY",
  CHANGE_PASSWORD: "CHANGE_PASSWORD"
};

export const CUSTOMER_ROUTES = {
  OVERVIEW: "OVERVIEW",
  FUNNELS: "FUNNELS",
  LEADS: "LEADS",
  PERFORMANCE_REPORT: "PERFORMANCE_REPORT",
  INVOICES: "INVOICES"
};

export const NESTED_SIDENAV = {
  FUNNEL: "FUNNEL",
  EVALUATION: "EVALUATION",
  ADS_EVALUATION: "ADS-EVALUATION",
  MISSING_INFO: "MISSING-INFO",
  SITE_SETTINGS: "SITE-SETTINGS",
  SETTING: "SETTING",
  CUSTOMER: "CUSTOMER",
  TOOLS: "TOOLS"
};

export const EVALUATION_TYPE = {
  OVERVIEW: "OVERVIEW",
  INSIGHTS: "INSIGHTS",
  OPTIMIZATION: "OPTIMIZATION"
};

export const CREATIVE_DASHBOARD_TABS = {
  OVERVIEW: "OVERVIEW",
  SUGGESTION_GALLERY: "SUGGESTION_GALLERY",
  FUNNEL_OVERVIEW: "FUNNEL_OVERVIEW"
};

export const MISSING_INFO_TYPE = {
  LEAD_INFO: "LEAD",
  CATEGORY_INFO: "CATEGORY",
  CUSTOMER_INFO: "CUSTOMER",
  HUBSPOT_INFO: "HUBSPOT",
  AD_ACCOUNT: "AD-ACCOUNT",
  UTM_PARAMETER: "UTM-PARAMETER"
};

export const LEAD_AD_ASSOCIATION_STATUS = {
  IGNORED: "IGNORED",
  HANDLED: "HANDLED"
};

export const SITE_SETTINGS_TYPE = {
  FACEBOOK_ACCOUNTS: "FACEBOOK-ACCOUNTS"
};

export const TOOLS_TYPE = {
  DUPLICATOR: "DUPLICATOR"
  // INSIGHTS: "INSIGHTS"
};

export const INTEGRATION_NAMES = {
  ZAPIER: "ZAPIER",
  SLACK: "SLACK",
  LEAD_SERVICE: "LEAD_SERVICE",
  EMAIL: "EMAIL",
  CONTACT_CLOUD: "CONTACT_CLOUD",
  APPOINTMENT_LINK: "APPOINTMENT_LINK",
  ADVERTISING_ACCOUNT_LINK: "ADVERTISING_ACCOUNT_LINK"
};

export const BASE_COUNTRY = "germany";

export const SEARCH_LEAD_BY = {
  PHONE: "PHONE",
  EMAIL: "EMAIL",
  AUTO: "AUTO",
  FUNNEL: "FUNNEL"
};

export const EURO_CURRENCY_SYMBOL = "€";

export const LEAD_SERVICE_PLUS_EDIT_MODES = {
  FOR_COMPANY: "forCompany",
  FOR_PRODUCT: "forProduct",
  FOR_PRICE: "forPrice",
  FOR_FIXED_PRICE: "forFixedPrice",
  FOR_UNIT: "forUnit",
  FOR_BUDGET_QUERY: "forBudgetQuery",
  FOR_PRICES_PER_AREA: "forPricesPerArea"
};

export const PRICE_TYPE = {
  DYNAMIC: "DYNAMIC",
  FIXED: "FIXED",
  AREA_QUERY: "AREA-QUERY"
};

export const THUMBNAIL_TYPE = {
  BACKGROUND: "background", // 1920 x 1080 -> desktop background (for funnel app)
  MOBILE_BACKGROUND: "mobile_background", // 480 x 800 -> mobile background (for funnel app)
  GALLERY_FORM_ASSET: "gallery_form_asset", // 230 x unknown -> gallery form asset
  COMPANY_LOGO: "company_logo", // 580 x unknown -> header logo (for funnel app)
  PARTNER_LOGO: "partner_logo", // 380 x unknown -> footer logo (for funnel app)
  ANSWER_OPTION_ICON: "answer_option_icon", // 214 x unknown -> answer option image
  GALLERY_MODAL_ASSET: "gallery_modal_asset" // 177 x unknown -> gallery modal image
};

export const FUNNEL_DESIGNS = {
  CLASSIC: "CLASSIC",
  MODERN: "MODERN"
};

export const LEAD_AGENT_TIMESTAMP_SESSION_EXPIRY = 30 * 60 * 1000;

export const TESTED_LINK_STATUS = {
  NEUTRAL: "NEUTRAL",
  POSITIVE: "POSITIVE",
  NEGATIVE: "NEGATIVE"
};

export const HUBSPOT_PROJECT_ID_LINK =
  "https://app.hubspot.com/contacts/4583994/record/2-7095914";

export const SCRIPT_ROUTE = {
  LS: "leadservice",
  LS_PLUS: "leadservice-plus"
};

export const DISPLAY_SUGGESTIONS = {
  ARCHIVED_ONES: "ARCHIVED_ONES",
  WINNING_ONES: "WINNING_ONES",
  NORMAL_ONES: "NORMAL_ONES"
};

export const LEAD_VIEW = {
  COMPLETED: "COMPLETED",
  PARTIAL: "PARTIAL"
};

export const NOT_SUITABLE_STAGE = "not_suitable";

export const CREATIVE_ASSET_INSIGHT_TYPE = {
  BODY_ASSET: "body_asset",
  TITLE_ASSET: "title_asset",
  DESCRIPTION_ASSET: "description_asset"
};

export const FORM_IMAGE_PLACEMENT = {
  BEFORE: "BEFORE",
  AFTER: "AFTER"
};

export const FUNNEL_PERFORMANCE_DURATION_FILTER = {
  WEEK: "BT_L7D",
  FORTNIGHT: "BT_L14D",
  MONTH: "BT_L30D"
};

export const SYSTEM_HEALTH_ALERT_ACTION = {
  FACEBOOK_AD_LEAD_FAILED: "FACEBOOK_AD_LEAD_FAILED"
};

export const SYSTEM_HEALTH_ALERT_ISSUE = {
  ERROR_READING: "ERROR_READING",
  UNKNOWN: "UNKNOWN",
  INVALID_ACCESS_TOKEN: "INVALID_ACCESS_TOKEN",
  API_LIMIT_REACHED: "API_LIMIT_REACHED"
};

export const LANDING_PAGE_TEMPLATES = {
  FUNNEL_LANDING_PAGE_TEMP_ONE: "FunnelLandingPageTpl1",
  FUNNEL_LANDING_PAGE_TEMP_TWO: "FunnelLandingPageTpl2"
};

export const QUESTION_POSITION = {
  next: "next",
  last: "last",
  multi: "multi"
};

export const DISBALE_LS_ACTIONS = {
  SYNC_NOW_AND_DISABLE: "SYNC_NOW_AND_DISABLE",
  DISABLE_NOW_AND_SYNC_LATER: "DISABLE_NOW_AND_SYNC_LATER"
};

export const CAMPAIGN_TASK = {
  CREATED: "CREATED",
  IGNORED: "IGNORED"
};
