import { Row, Col, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  Legend,
  FieldSet,
  CustomCard,
  FunnelTypeBadge,
  HorizontalDivider
} from "../common-styling";
import {
  designObj,
  textElemObj,
  thankYouPageObj
} from "../common-confgs/common-vars";
import Loader from "../loader/loader";
import {
  updateTemplate,
  getTemplateById,
  createDraftTemplate
} from "../../services/api-declaration";
import PreventReload from "../funnel-form/prevent-reload";
import TextField from "../reusable-form-fields/text-field";
import SpinnerButton from "../spinner-button/spinner-button";
import { resetAnswers } from "../../services/common-service";
import LayoutSection from "../reusable-form-section/layout-section";
import FunnelOutcomes from "../funnel-form/funnel-outcomes/outcomes";
import { TemplateFormSchema } from "../../services/validation-schema";
import { showError, showSuccess } from "../../services/toast-service";
import FunnelQASection from "../reusable-form-section/funnel-qa-section";
import TextElementsSection from "../reusable-form-section/text-elements-section";
import ThankYouPageSection from "../reusable-form-section/thank-you-page-section";
import { useTranslation } from "react-i18next";

const TemplateForm = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const templateTypeParam = searchParams.get("templateType");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [resourceId, setResourceId] = useState(params.id);
  const [template, setTemplate] = useState();
  const templateType = params.id ? template?.templateType : templateTypeParam;
  const methods = useForm({
    resolver: yupResolver(TemplateFormSchema),
    defaultValues: {
      name: "",
      templateType,
      funnelQA: [],
      outcomes: [],
      design: designObj,
      textElements: textElemObj,
      thankyouPage: thankYouPageObj
    }
  });
  const {
    formState: { errors },
    reset,
    register,
    getValues,
    setFocus,
    handleSubmit
  } = methods;
  const handleCreate = async (values) => {
    try {
      const formValues = JSON.parse(JSON.stringify(values));
      setIsSubmitting(true);
      const payload = {
        ...formValues,
        draft: false
      };
      const response = await updateTemplate(resourceId, payload);
      showSuccess(response.data.message);
      searchParams.set("templateType", templateType);
      setSearchParams(searchParams);
      navigate({
        pathname: `../${resourceId}`,
        search: searchParams.toString()
      });
    } catch (error) {
      console.log(error);
      showError(
        error.response.data.message || t("toastMessage.createTemplateError")
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleUpdate = async (values) => {
    try {
      const formValues = JSON.parse(JSON.stringify(values));
      setIsSubmitting(true);
      const payload = {
        ...formValues
      };
      const response = await updateTemplate(params.id, payload);
      showSuccess(response.data.message);
    } catch (error) {
      console.log(error);
      showError(
        error.response.data.message || t("toastMessage.updateTemplate")
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  const onSubmit = (formValues) => {
    formValues.funnelQA = resetAnswers(formValues);
    if (params.id) {
      handleUpdate(formValues);
    } else {
      handleCreate(formValues);
    }
  };

  useEffect(() => {
    if (!params.id) {
      const handleCreateDraft = async () => {
        try {
          const response = await createDraftTemplate();
          setResourceId(response.data.data._id);
        } catch (err) {
          console.log(err);
          showError(
            err.response.data.message || t("toastMessage.draftTemplate")
          );
        } finally {
          setIsLoading(false);
        }
      };
      handleCreateDraft();
    }
    // eslint-disable-next-line
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      const loadTemplate = async () => {
        try {
          setIsLoading(true);
          const response = await getTemplateById(params.id);
          const data = response.data.data;
          reset(data);
          setTemplate(data);
        } catch (error) {
          showError(
            error.response.data.message ||
              t("toastMessage.loadingTemplateError")
          );
          if (error.response.status === 422 || error.response.status === 404) {
            navigate("..");
          }
        } finally {
          setIsLoading(false);
        }
      };
      loadTemplate();
    }
    // eslint-disable-next-line
  }, [params.id, navigate, reset]);

  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((key) => {
        if (key === "funnelQA") {
          setFocus(`funnelQA[${0}].text`);
        }
      });
    }
  }, [errors, setFocus]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {/* Container on above fragment */}
      <div className="m-0">
        <FormProvider {...methods}>
          <PreventReload />
          <form
            onSubmit={(event) => {
              handleSubmit(onSubmit)(event);
            }}
          >
            <div className="pt-4 pb-2">
              <div className="d-flex align-items-center mb-4">
                <div className="d-flex align-items-center flex-fill">
                  <h5 className="mb-0 me-4">
                    {params.id ? t("updateTemplate") : t("createTemplate")}
                  </h5>
                  <FunnelTypeBadge
                    className="px-3"
                    btnbg={`--${templateType?.toLowerCase()}-badge-bg`}
                    btncolor={`--${templateType?.toLowerCase()}-color`}
                  >
                    {templateType?.toLowerCase()}
                  </FunnelTypeBadge>
                </div>
                {params.id && (
                  <div>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>{t("createFunnelFromTemplate")}</Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        onClick={() =>
                          navigate(
                            `/dashboard/funnels/new?funnelType=${templateType}`,
                            {
                              state: {
                                navigatedTemplate: {
                                  name: getValues("name"),
                                  _id: getValues("_id"),
                                  templateType,
                                  funnelQA: getValues("funnelQA"),
                                  outcomes: getValues("outcomes"),
                                  design: getValues("design"),
                                  textElements: getValues("textElements"),
                                  thankyouPage: getValues("thankyouPage")
                                }
                              }
                            }
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        <span className="ms-2">{t("createNewFunnel")}</span>
                      </Button>
                    </OverlayTrigger>
                  </div>
                )}
              </div>
              <CustomCard className="my-4">
                <div className="m-3">
                  <FieldSet className="mt-2">
                    <Legend>
                      <h6 className="mb-0 fw-bold">{t("basics")}</h6>
                    </Legend>
                    <div className="px-0">
                      <Row>
                        <Col xs={12} md={6}>
                          <TextField
                            label={t("common.templateName")}
                            placeholder={t("common.templateName")}
                            type="text"
                            name="name"
                            errors={errors?.name}
                            register={register}
                            noSpacing
                          />
                        </Col>
                      </Row>
                    </div>
                  </FieldSet>
                </div>
              </CustomCard>
            </div>
            <CustomCard className="mb-3">
              <div className="px-3">
                <div className="mt-4">
                  <FunnelQASection
                    parentFolderName="template"
                    resourceId={resourceId}
                    errors={errors?.funnelQA}
                  />
                </div>
                <HorizontalDivider />
                <FunnelOutcomes />
                <HorizontalDivider />
                <LayoutSection
                  parentFolderName="template"
                  resourceId={resourceId}
                />
                <HorizontalDivider />
                <TextElementsSection
                  resourceId={resourceId}
                  parentFolderName="template"
                />
                <HorizontalDivider />
                <ThankYouPageSection />
                <HorizontalDivider />
                <div className="mb-3 px-0">
                  {!isSubmitting ? (
                    <Button type="submit" variant="primary">
                      {t("common.save")} {t("common.template")}
                    </Button>
                  ) : (
                    <SpinnerButton
                      btnText={`${t("common.save")} ${t("common.template")}`}
                      btnStyle="btn-primary"
                    />
                  )}
                </div>
              </div>
            </CustomCard>
          </form>
        </FormProvider>
      </div>
    </>
  );
};
export default TemplateForm;
