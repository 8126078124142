import styled from "styled-components";
import {
  faEye,
  faMinus,
  faCircleXmark,
  faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import {
  TableRow,
  CustomCard,
  PageHeading,
  HeaderColumn,
  BootstrapTable,
  TableDetailCell,
  LastHeaderColumn,
  FirstHeaderColumn,
  TablePercentageCell
} from "../common-styling";
import {
  generateDate,
  getCurrencyData,
  getFormattedNumber
} from "../../services/common-service";
import {
  fetchCampaignOptimization,
  updateCampaignOptimizationTask
} from "../../services/api-declaration";
import { CAMPAIGN_TASK } from "../strings";
import TableLoader from "../loader/table-loader";
import NoResultsRow from "../no-results-table-row";
import { showError } from "../../services/toast-service";
import CustomPagination from "../pagination/custom-pagination";
import { useTranslation } from "react-i18next";

const FunnelBadgeFilled = styled(Badge)`
  padding: 8px;
  min-width: 100%;
  text-transform: capitalize;
  color: ${({ btncolor }) => `var(${btncolor})`};
  background-color: ${({ btnbg }) => `var(${btnbg})`} !important;
`;

const PercentageBadge = styled(Badge)`
  text-transform: capitalize;
  font-size: 9px;
  color: ${({ btncolor }) => `var(${btncolor})`};
  background-color: ${({ btnbg }) => `var(${btnbg})`} !important;
`;

const LineDiv = styled.div`
  width: 45px;
  height: 1px;
  background: var(--th-border-color);
`;

const AdOptimization = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [optimizationData, setOptimizationData] = useState(true);
  const [paginateOpts, setPaginateOpts] = useState(true);
  const [loadingById, setLoadingById] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const colSpan = 19;

  const differenceInPercent = (actualLeadPrice, leadPriceBenchmarkValue) =>
    Math.round(
      (((actualLeadPrice - leadPriceBenchmarkValue) * 100) /
        leadPriceBenchmarkValue) *
        100
    ) / 100;

  const qualifiedInPercent = (qualify, lead) =>
    Math.round((qualify / lead) * 100);

  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };

  const onSave = async (status, item, problem) => {
    const campaign_id = item.campaign_id;
    let prob = "Anzahl der Leads";
    if (problem === "leadCost") {
      prob = "Kosten pro Lead";
    } else if (problem === "leadQualify") {
      prob = "Qualifizierten Leads";
    }
    const data = {
      ...item,
      differenceInPercent: differenceInPercent(
        item.cpql || item.cpl,
        item.benchmark
      ),
      problem: prob,
      status
    };
    const load = { ...loadingById };
    load[campaign_id] = "loading";
    setLoadingById(load);
    try {
      const response = await updateCampaignOptimizationTask(campaign_id, data);
      if (response.data.data) {
        load[campaign_id] = "hide";
      }
      setLoadingById({ ...load });
    } catch (error) {
      load[campaign_id] = "failed";
      showError(error?.response?.data?.message);
      setLoadingById({ ...load });
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const params = {};
        for (const [key, value] of searchParams.entries()) {
          params[key] = value;
        }
        const response = await fetchCampaignOptimization(params);
        const data = response.data.data;
        const metaData = data.metadata;
        if (!data.data.length) {
          setOptimizationData([]);
          setPaginateOpts({});
          return;
        }
        setOptimizationData(data.data);
        setPaginateOpts({
          totalPages: Math.ceil(metaData.total / metaData.limit),
          hasPrevPage: metaData.hasPrevPage,
          hasNextPage: metaData.hasNextPage,
          totalDocs: metaData.total,
          rowsOnPage: metaData.length
        });
      } catch (error) {
        showError(
          error?.response?.data?.message ||
            t("toastMessage.errorInGetting", {
              what: t("funnelsEvaluation", { count: 2 })
            })
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
    // eslint-disable-next-line
  }, [searchParams]);

  return (
    <>
      <div>
        <PageHeading>{t("sidebar.adOptimization")}</PageHeading>
        <CustomCard className="mt-4 mb-3 p-3 rounded-0">
          <BootstrapTable responsive>
            <thead>
              <tr>
                <FirstHeaderColumn>
                  Campaign {t("common.name")}
                </FirstHeaderColumn>
                <HeaderColumn>Customer</HeaderColumn>
                <HeaderColumn>Category</HeaderColumn>
                <HeaderColumn className="text-center">
                  {t("common.type")}
                </HeaderColumn>
                <HeaderColumn className="text-center">
                  {t("common.problem")}
                </HeaderColumn>
                <HeaderColumn className="text-start">
                  {t("common.lead_other")}
                </HeaderColumn>
                <HeaderColumn className="text-end">
                  {t("leadAgent.qualified")} {t("common.lead_other")}
                </HeaderColumn>
                <HeaderColumn className="text-center">
                  {t("advancedFilter.fbSort.costPerLead")}
                </HeaderColumn>
                <HeaderColumn className="text-center">
                  {t("advancedFilter.fbSort.costPerQualified")}
                </HeaderColumn>
                <HeaderColumn>{t("common.benchmark")}</HeaderColumn>
                <HeaderColumn>{t("common.difference")}</HeaderColumn>
                <HeaderColumn>{t("common.difference")} in %</HeaderColumn>
                <HeaderColumn className="text-center">
                  {t("advancedFilter.fbSort.spend")}
                </HeaderColumn>
                <HeaderColumn className="text-center">
                  {t("advancedFilter.fbSort.click")}
                </HeaderColumn>
                <HeaderColumn className="text-center">
                  {t("advancedFilter.fbSort.costPerClick")}
                </HeaderColumn>
                <HeaderColumn className="text-center">
                  {t("advancedFilter.fbSort.reach")}
                </HeaderColumn>
                <HeaderColumn className="text-center">
                  {t("advancedFilter.fbSort.impressions")}
                </HeaderColumn>
                <HeaderColumn className="text-center">
                  {t("lastTaskLabel")}
                </HeaderColumn>
                <LastHeaderColumn className="text-center">
                  {t("common.action")}
                </LastHeaderColumn>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableLoader colSpan={colSpan} />
              ) : optimizationData.length ? (
                optimizationData.map((item) => {
                  const currency = item.account_currency || "EUR";
                  const actualRateInPercent = differenceInPercent(
                    item.cpql || item.cpl,
                    item.benchmark
                  );
                  const qualifiedPercent = qualifiedInPercent(
                    item.leadQualifiedCount || 0,
                    item.leadCount > 0 ? item.leadCount : 1
                  );

                  return (
                    <TableRow key={item._id} className="optimization-table-row">
                      {/* <TableDetailNameCell
                        style={{ maxWidth: 400 }}
                        className="py-3 align-middle"
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-fill">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip>{item.campaign_name}</Tooltip>}
                            >
                              <NameLink
                                className="d-block"
                                to={`/dashboard/funnels/${item.funnelId}`}
                              >
                                {item.campaign_name}
                              </NameLink>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </TableDetailNameCell> */}
                      <TableDetailCell className="py-3">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>{item.campaign_name}</Tooltip>}
                        >
                          <span>{item.campaign_name}</span>
                        </OverlayTrigger>
                      </TableDetailCell>
                      <TableDetailCell className="py-3">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>{item.customerName}</Tooltip>}
                        >
                          <span>{item.customerName}</span>
                        </OverlayTrigger>
                      </TableDetailCell>
                      <TableDetailCell className="py-3">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>{item.categoryName}</Tooltip>}
                        >
                          <span>{item.categoryName}</span>
                        </OverlayTrigger>
                      </TableDetailCell>
                      <TableDetailCell className="py-3 align-middle">
                        <FunnelBadgeFilled
                          btnbg={`--${item.funnelType?.toLowerCase()}-badge-bg`}
                          btncolor={`--${item.funnelType?.toLowerCase()}-color`}
                        >
                          {item.funnelType?.toLowerCase()}
                        </FunnelBadgeFilled>
                      </TableDetailCell>
                      <TableDetailCell className="py-3">
                        <FunnelBadgeFilled
                          btnbg={`--lead-${item.problem}-problem-badge-bg`}
                          btncolor={`--lead-${item.problem}-problem-color`}
                        >
                          {t(`advancedFilter.fbSort.${item.problem}`)}
                        </FunnelBadgeFilled>
                      </TableDetailCell>
                      <TablePercentageCell
                        colSpan={2}
                        className="py-3 text-center"
                      >
                        <div className="d-flex align-items-center justify-content-around">
                          {getFormattedNumber(item.leadCount)}
                          <div className="d-flex align-items-center">
                            <LineDiv />
                            <PercentageBadge
                              className="mx-2"
                              btnbg={`--lead-${
                                item.isLeadQualifyProblem ? "danger" : "success"
                              }-badge-bg`}
                              btncolor={`--lead-${
                                item.isLeadQualifyProblem ? "danger" : "success"
                              }-color`}
                            >
                              {qualifiedPercent}%
                            </PercentageBadge>
                            <LineDiv>
                              <div className="open-lead-div">
                                {item.openLeadCount}
                              </div>
                            </LineDiv>
                          </div>
                          {getFormattedNumber(item.leadQualifiedCount || 0)}
                        </div>
                      </TablePercentageCell>
                      <TableDetailCell
                        className={`py-3 text-center ${
                          item.difference < 0 ? "t-danger" : ""
                        }`}
                      >
                        {getCurrencyData(currency, item.cpl || 0)}
                      </TableDetailCell>
                      <TableDetailCell
                        className={`py-3 text-center ${
                          item.difference < 0 ? "t-danger" : ""
                        }`}
                      >
                        {getCurrencyData(currency, item.cpql || 0)}
                      </TableDetailCell>
                      <TableDetailCell
                        className={`py-3 ${
                          item.difference < 0 ? "t-success" : ""
                        }`}
                      >
                        {getCurrencyData(currency, item.benchmark || 0)}
                      </TableDetailCell>
                      <TableDetailCell
                        className={`py-3 ${
                          item.difference === 0
                            ? ""
                            : item.difference > 0
                              ? "t-success"
                              : "t-danger"
                        }`}
                      >
                        {getCurrencyData(currency, item.difference)}
                      </TableDetailCell>
                      <TableDetailCell
                        className={`py-3 ${
                          item.difference === 0
                            ? ""
                            : item.difference > 0
                              ? "t-success"
                              : "t-danger"
                        }`}
                      >
                        {actualRateInPercent !== Infinity &&
                        !isNaN(actualRateInPercent) ? (
                          actualRateInPercent > 0 ? (
                            `+${actualRateInPercent}%`
                          ) : (
                            `${actualRateInPercent}%`
                          )
                        ) : (
                          <FontAwesomeIcon icon={faMinus} />
                        )}
                      </TableDetailCell>
                      <TableDetailCell className="py-3 text-center">
                        {getCurrencyData(currency, item.spend || 0)}
                      </TableDetailCell>
                      <TableDetailCell className="py-3 text-center">
                        {getFormattedNumber(item.clicks || 0)}
                      </TableDetailCell>
                      <TableDetailCell className="py-3 text-center">
                        {getCurrencyData(currency, item.cpc || 0)}
                      </TableDetailCell>
                      <TableDetailCell className="py-3 text-center">
                        {getFormattedNumber(item.reach || 0)}
                      </TableDetailCell>
                      <TableDetailCell className="py-3 text-center">
                        {getFormattedNumber(item.impressions || 0)}
                      </TableDetailCell>
                      <TableDetailCell className="py-3 text-center">
                        {item.hubspotTask?.status === CAMPAIGN_TASK.CREATED
                          ? generateDate(item.hubspotTask?.taskUpdated)
                          : "---"}
                      </TableDetailCell>
                      <TableDetailCell className="py-3 text-center">
                        {loadingById[item.campaign_id] === "hide" ? (
                          <></>
                        ) : loadingById[item.campaign_id] === "loading" ? (
                          <Spinner variant="primary" animation="border" />
                        ) : item.hubspotProjectId ? (
                          <>
                            {item.funnelId && (
                              <Link
                                style={{ color: "inherit" }}
                                target="_blank"
                                to={`/dashboard/funnels/${item.funnelId}/form?funnelType=${item.funnelType}`}
                              >
                                <FontAwesomeIcon
                                  size="xl"
                                  className="me-2 cursor-pointer"
                                  icon={faEye}
                                />
                              </Link>
                            )}
                            <FontAwesomeIcon
                              size="xl"
                              className="me-2 t-success cursor-pointer"
                              icon={faCircleCheck}
                              onClick={() => {
                                onSave("CREATED", item, item.problem);
                              }}
                            />
                            <FontAwesomeIcon
                              size="xl"
                              className="me-2 t-danger cursor-pointer"
                              icon={faCircleXmark}
                              onClick={() => {
                                onSave("IGNORED", item, item.problem);
                              }}
                            />
                          </>
                        ) : (
                          <Link
                            style={{ color: "inherit" }}
                            target="_blank"
                            to={`/dashboard/funnels/${item.funnelId}/form?funnelType=${item.funnelType}`}
                          >
                            <p className="m-0">Link Hubspot</p>
                          </Link>
                        )}
                      </TableDetailCell>
                    </TableRow>
                  );
                })
              ) : (
                <NoResultsRow lengthOfColumns={colSpan} />
              )}
            </tbody>
          </BootstrapTable>
        </CustomCard>
        {optimizationData.length ? (
          <CustomPagination
            paginateOpts={paginateOpts}
            activeTab={Number(searchParams.get("pageIndex")) || 1}
            select={(val) => handleQueryParams("pageIndex", val)}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default AdOptimization;
