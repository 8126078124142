import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  faEye,
  faSync,
  faPhone,
  faMessage,
  faEnvelope,
  faArrowRotateLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ACTIVITY_TYPES } from "../strings";
import { FontSizeTwelve } from "../common-styling";
import RoundedIconContainer from "../rounded-icon-container";
import { generateDateTime } from "../../services/common-service";
import { useTranslation } from "react-i18next";

const activityIcons = {
  [ACTIVITY_TYPES.CALL]: faPhone,
  [ACTIVITY_TYPES.SMS]: faMessage,
  [ACTIVITY_TYPES.EMAIL]: faEnvelope,
  [ACTIVITY_TYPES.RESET]: faArrowRotateLeft,
  [ACTIVITY_TYPES.SYNC_MANUALLY]: faSync,
  [ACTIVITY_TYPES.PARTIAL_SYNC_MANUALLY]: faSync
};

const ActivityDivider = styled.div`
  width: 2px;
  height: 35px;
  margin-left: 18px;
  background: var(--activity-divider-color);
`;

const LeadActivities = ({ activities }) => {
  const { t } = useTranslation();
  return activities.length ? (
    activities.map(({ type, result, note, createdAt, serviceAgent }, index) => (
      <div key={index} className="mb-2">
        <div className="d-flex align-items-center">
          <RoundedIconContainer
            iconColor="primary"
            borderColor="primary"
            icon={activityIcons[type]}
          />
          <div className="flex-fill mx-3">
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>{t("common.type")}</Tooltip>}
            >
              <div className="fw-bold">{t(`leadActivities.type.${type}`)}</div>
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>{t("common.result")}</Tooltip>}
            >
              <div>{t(`leadActivities.resultType.${result}`)}</div>
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>{t("leadActivities.noteTooltip")}</Tooltip>}
            >
              <div className="fw-bold">{note || "---"}</div>
            </OverlayTrigger>
            <FontSizeTwelve className="m-0 text-muted">
              {generateDateTime(createdAt)}
            </FontSizeTwelve>
          </div>
          {/* Agent Info */}
          <div className="text-end">
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip>
                  {t("leadActivities.agentInfo")}:
                  <div>
                    {serviceAgent.firstName} {serviceAgent.lastName}
                  </div>
                </Tooltip>
              }
            >
              <Button
                size="sm"
                as={Link}
                target="_blank"
                variant="outline-primary"
                to={`/dashboard/user/${serviceAgent._id}`}
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
            </OverlayTrigger>
          </div>
        </div>
        {index !== activities.length - 1 && (
          <div className="mt-2">
            <ActivityDivider></ActivityDivider>
          </div>
        )}
      </div>
    ))
  ) : (
    <div className="py-2">{t("leadActivities.noActivityMsg")}</div>
  );
};
export default LeadActivities;
