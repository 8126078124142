import { useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalTitle, Button, Form, FormGroup } from "react-bootstrap";
import {
  createFunnelLandingPage,
  updateFunnelLandingPage
} from "../../../../services/api-declaration";
import { useOptionsFetch } from "../../../../hooks/useOptionsFetch";
import AsyncSelectField from "../../../reusable-form-fields/async-select";
import { showError, showSuccess } from "../../../../services/toast-service";
import { DuplicateLandingPageSchema } from "../../../../services/validation-schema";
import { useTranslation } from "react-i18next";

const ModalSubheading = styled.div`
  color: #6c757d;
  font-size: 12px;
  font-weight: normal;
`;

const DuplicateTemplateModal = ({
  show,
  toggle,
  formData,
  fetchedLandingPageId
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const { handleFunnelOptions } = useOptionsFetch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm({
    resolver: yupResolver(DuplicateLandingPageSchema),
    defaultValues: { selectedFunnel: "" }
  });
  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = methods;
  const isCurrentFunnelSelected = params.id === watch("selectedFunnel")?._id;

  const handleOnClose = () => {
    toggle();
    reset({});
  };
  const onSave = async (modalFormValues) => {
    setIsSubmitting(true);
    try {
      const { enabled, template } = formData;
      const { ...restVals } = formData.data;
      const selectedFunnelId = modalFormValues.selectedFunnel._id;
      const { selectedFunnel } = modalFormValues;
      // create or update pre landing page
      const response = await createFunnelLandingPage({
        ...restVals, // using resvals ref at first because it has old funnelId and duplicatedFromLandingPage
        template,
        funnelId: selectedFunnelId,
        duplicatedFromLandingPage: fetchedLandingPageId
      });
      const landingPageId = response.data.data._id;
      if (landingPageId) {
        try {
          const updateFunnelPayload = {
            landingPageId,
            isLandingPageEnabled: enabled,
            landingPageTemplateType: template._id,
            addNewVersion: true
          };
          // update selected funnel
          await updateFunnelLandingPage(selectedFunnelId, {
            ...updateFunnelPayload
          });
          showSuccess(
            t("preLandingPage.duplicateSuccessMsg", {
              funnelName: selectedFunnel.general.funnelName
            })
          );
          handleOnClose();
        } catch (error) {
          console.log("Error in duplicating landing page for funnel", error);
          showError(
            error.response?.data?.message ||
              "Error in duplicating landing page for funnel"
          );
        }
      }
    } catch (error) {
      console.log("Error in saving data: ", error);
      showError(error.response?.data?.message || "Error in saving data");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <ModalTitle>
          <div>
            <div>{t("preLandingPage.duplicateLabel")}</div>
            <ModalSubheading>
              {t("preLandingPage.duplicateSubLabel")}
            </ModalSubheading>
          </div>
        </ModalTitle>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSave)}>
            <FormGroup className="mb-3" controlId="category">
              <Form.Label
                className={errors?.selectedFunnel ? "text-danger" : ""}
              >
                {`Funnel:${errors?.selectedFunnel ? "*" : ""}`}
              </Form.Label>
              <AsyncSelectField
                control={control}
                name="selectedFunnel"
                paramName="funnelName"
                showDefaultOpts={false}
                fetchData={handleFunnelOptions}
                fieldError={errors?.selectedFunnel}
                placeholder={`${t("common.search")} funnel`}
                getOptionLabel={(option) =>
                  `${option.general?.funnelName} (${option.general?.funnelType})`
                }
              />
              {isCurrentFunnelSelected && (
                <small className="fw-bold text-danger">
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  <span className="ps-1">
                    {t("preLandingPage.sameFunnelWarning")}
                  </span>
                </small>
              )}
            </FormGroup>
            <div className="text-end">
              <Button onClick={handleOnClose} variant="secondary">
                {t("common.cancel")}
              </Button>
              <Button
                type="submit"
                className="ms-2"
                variant="success"
                disabled={isSubmitting || !isDirty || isCurrentFunnelSelected}
              >
                {t("common.save")}
              </Button>
            </div>
          </Form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};
export default DuplicateTemplateModal;
