import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AssociatedIntegrationActionButton = ({
  text,
  action,
  variant,
  eventType,
  handleClick
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-100">
      <Button
        size="sm"
        className="w-100"
        variant={`outline-${variant}`}
        onClick={() => handleClick(action, eventType)}
      >
        {t(text)}
      </Button>
    </div>
  );
};
export default AssociatedIntegrationActionButton;
