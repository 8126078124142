export const EN = {
  adName: "Image",
  averageCalls: "Average calls",
  funnelFilters: {
    multiPCHeading: "Locations to be included",
    multiPCCaption: "Target multiple Postal Codes",
    multiPCPlaceHolder: "Enter Postal Codes seperated by row",
    clearAll: "Clear All",
    addLocations: "Add Locations",
    germany: "Germany",
    switzerland: "Switzerland",
    austria: "Austria"
  },
  leadpannel: {
    leadCountTitle: "Lead service key figures",
    leadRateTitle: "Lead Rate",
    syncedLeads: "Synced Leads",
    unsyncedLeads: "Unsynced Leads",
    totalLeads: "Total Leads"
  },
  invoice: {
    heading: "Invoices",
    invoiceNumber: "Invoice Number",
    invoiceDate: "Invoice Date",
    invoiceAmount: "Invoice Amount",
    download: "Download",
    downloading: "Downloading...",
    preview: "Preview",
    opening: "Opening...",
    sendToCust: "Send to Customer",
    sending: "Sending...",
    noInvoices: "No Invoices Found",
    noInvoicesForYear: "No invoices found for this year"
  },
  toastMessage: {
    templateAppliedSuccess: "Template Applied",
    disable2FAError: "Error in disabling 2FA!",
    verificationError: "Error in verification!",
    qrCodeError: "Error in generating QR Code!",
    errorInGetting: "Error in getting {{what}}",
    errorInUpdating: "Error in updating {{what}}",
    updateFBToken: "Error in updating FB token!",
    selectAssetError: "Error in selecting asset!",
    funnelByDomain: "Error loading funnel through domain and subdomain!",
    updateLeadError: "Error in Updating Lead Data!",
    update: "Error in Updating!",
    duplicateFunnelError: "Error in duplicating funnel!",
    duplicateFunnelSuccess: "Funnel duplicated successfully!",
    duplicateFunnelWarning:
      "Unable to duplicate this funnel because it has incomplete information!",
    noTenantIdWarning:
      "There is no tenant id, you need to add contact cloud id in this funnel's customer!",
    createAssetError: "Error in creating asset!",
    createUserError: "Error in Creating User!",
    // assignUserTokenError: "Error assigning user token!",
    fetchUsersError: "Error in fetching Users!",
    updateUserError: "Error in Updating User!",
    downloadError: "Error in downloading image!",
    uploadItemError: "Error in uploading item!",
    availableVersion: "Error while looking for available version!",
    importFunnel: "Error in importing funnel!",
    createTemplateError: "Error in creating template!",
    archiveError: "Error in archive!",
    unarchiveError: "Error in unarchive!",
    assetsUploadError: "Error in uploading assets",
    fbPagesError: "Error in fetch fb pages!",
    draftFunnel: "Error in creating draft funnel!",
    draftTemplate: "Error in creating draft template!",
    deleteTemplate: "Error in deleting template!",
    fbFormError: "Error retrieving Facebook Page forms!",
    wentWrong: "Something went wrong!",
    categoryByName: "Category by Name!",
    categoryByID: "Category by id!",
    saveCategory: "Error in saving category!",
    changePassword: "Error in Changing Password!",
    fetchInsightsError: "Error in Fetching insights!",
    saveCustomer: "Error is saving customer!",
    createCity: "Error in creating new city!",
    updateFunnels: "Error in updating funnel!",
    updateTemplate: "Error in updating template!",
    updateFunnelFilters: "Error in updating funnel filters",
    removeDomain: "Error in removing domain & sub-domain",
    fetchVersion: "Error in fetching version!",
    changeVersion: "Error in changing version!",
    downloadSuccessfully: "Download Successfully!",
    csvDownloadError: "Error in downloading csv!"
  },
  fbTokenInfoMessage: "Currenly using <strong>{{what}}</strong> facebook token",
  availableAfter: "After how many hours will the requests be available again?",
  createdLeads: "Number of Leads created",
  numberOfVisits: "Number of Visits",
  outcome: {
    jumpDirect: "Jump directly to this outcome",
    jumpEnd: "Only jump to this outcome at the end",
    restart: "Restart Questionaire"
  },
  settings: {
    welcome: "Welcome to profile settings:",
    connectFB: "Connect with Facebook"
  },
  // siteSettings: {
  //   token: "Token"
  // },
  funnelIntegration: {
    tabTitleActiveTooltip: "Has active integrations",
    tabTitleNotIntegratedTooltip: "Not Integrated",
    error: "Error in integration!",
    updateIntegration: "Error in updating integration!",
    removeIntegration: "Error in removing integration!",
    descriptionPlaceholder: "Add integration description here",
    deleteIntegrationWarning: `Do you want to <strong>delete</strong> this integration?`,
    deleteAppointmentLinkWarning: `Do you want to <strong>delete</strong> appointment link?`,
    deleteAccountLinkWarning: `Do you want to <strong>delete</strong> account link?`,
    webhookPlaceholder:
      "Enter webhook URL (you get this information from Zapier)",
    updateToastMsg: "Integration updated successfully!",
    enableDialogMsg: "Do you want to <strong>enable</strong> this integration?",
    disableDialogMsg:
      "Do you want to <strong>disable</strong> this integration?",
    sendDataHeading: "Send Data",
    selectOptions: {
      never: "Never",
      immediately: "Send Immediately",
      afterConfirmation: "Send after confirmation",
      leadServiceInfo: "Enable Lead Service 2.0 first!"
    },
    leadService: {
      enableDialogMsg:
        "Do you want to <strong>enable</strong> lead service 2.0?",
      disableDialogMsg:
        "Do you want to <strong>disable</strong> lead service 2.0?",
      warningDialogMessage: `By disabling lead service 2.0, your settings for send <strong>email</strong> to customer, <strong>contact cloud</strong> or <strong>zapier</strong> integration will be compromised!<div>Do you want to continue?</div>`,
      enablePlusMsg: "Do you want to <strong>enable</strong> lead service+?",
      disablePlusMsg: "Do you want to <strong>disable</strong> lead service+?",
      leadServiceCompanyNameLabel: "Leadservice Company Name",
      leadServiceProductNameLabel: "Product Name"
    },
    leadServicePlus: {
      priceTypeLabel: "Price Type",
      calculationLabel: "Calculation",
      fixedPriceLabel: "Fixed Price",
      areaQueryLabel: "Area Query",
      pricePerInvoiceUnitLabel: "Price per invoice unit",
      fixedPricePerInvoiceUnitLabel: "Fixed price per invoice unit",
      nameOfAccountingUnitLabel: "Name of accounting unit",
      specifyHerePlaceholder: "Specify here",
      budgetQueryLimitsLabel: "Budget query limits",
      pricePerAreaLabel: "Price per area",
      disabledBudgetLimitRadioMsg: "Add Name of accounting unit to enable",
      removeUnitNameConfirmationMsg: `If you remove the <strong>Name of accounting unit</strong>, your <strong>Price Type</strong> for <strong>Area Query</strong> will be affected!<div>Do you want to continue?</div>`
    },
    alternativeAddress: {
      checkboxLabel: "Alternative Address",
      error: "Failed to update alternative address"
    },
    email: {
      alertDialogMessage: `Sorry, you need to enable <strong>lead service 2.0</strong> first!`,
      label: "Send Email To Customer",
      toastError: "Error in updating customer email configuration!"
    },
    slack: {
      checkboxLabel: "Slack Notifications",
      fieldLabel: "Notification-URL",
      fieldPlaceholder:
        "Enter the notification URL (you get this information from Slack).",
      toastError: "Error in slack integration"
    },
    contactCloud: {
      connectBtn: "Connect",
      contactCloudSetup: "Contact Cloud Setup",
      optionsError: {
        users: "user options",
        products: "product options",
        jobs: "job options"
      }
    },
    appointment: {
      heading: "Customer Appointment",
      label: "Appointment Link",
      placeholder: "Add appointment Link"
    },
    advertisingAccount: {
      heading: "Advertising Account",
      label: "Account Link",
      placeholder: "Enter account link"
    }
  },
  modalContent: {
    saveAsTemplate: "Save as{{new}}template",
    deleteOutcome: `Are you sure you want to <span class="text-danger">delete</span> this outcome? 
    <div>Because it is a response action in <span class="text-danger fw-bold">funnel QA</span><div>`,
    deleteFunnel: `Are you sure you want to <span class="text-danger">delete</span> this question?`,
    responseAction: "Because it's a response action in",
    funnel: {
      unsavedWarning:
        "You have unsaved changes. Save this to save as a template",
      unarchiveFunnelTitle: "Remove funnel from archive",
      unarchiveFunnelMsg: `Do you want to <span class="fw-bold"> remove </span> this <span class="fw-bold"> funnel from the archive? </span>`,
      note: "This funnel does not have a domain and subdomain!",
      discardUnsavedWarning: `Your unsaved funnel will be <strong>discarded</strong>. Do you want to continue?`
    }
  },
  adCopy: {
    title: "Ad Copy",
    noAdMessage: "No Ad Extracted",
    dataCompleted: "Training Data Completed!",
    emojis: "Emojis",
    funnelURL: "Funnel-URL"
  },
  adDuplicator: {
    fetchAdAcc: "Fetch ad accounts",
    fetchAd: "Fetch Ad",
    pasteAndFetchAd: "Paste and Fetch Ad",
    uploadAdsToFb: "Upload Ads to Facebook",
    clearAllAds: "Clear All",
    title: "Ad Duplicator",
    viewIdLabel: "Ad-ID",
    imgStatus: "Image status",
    adStatus: "Ad status",
    disabledDropImageMsg: "Verify Ad To Upload",
    adUploadingStatus: {
      waiting: {
        status: "READY TO CREATE",
        message: "I'm waiting to create a Facebook ad.."
      },
      uploading: {
        status: "UPLOADING...",
        message: "Creating ad to facebook."
      },
      success: { status: "SUCCESS", message: "Ad created Successfully." },
      rejected: { status: "REJECTED" }
    }
  },
  basics: "Basics",
  leadSyncMsg:
    "Lead is already synced. Do you want to <strong>sync</strong> again?",
  passwordChangeMsg: "Password successfully changed!",
  overview: "Overview",
  infoDetails: "Info-Details",
  hubspotID: "Hubspot ID",
  hubspotProjectID: "Hubspot Project ID",
  contactCloudID: "Contact Cloud ID",
  createNewFunnel: "Create New Funnel",
  updateTemplate: "Update Template",
  createTemplate: "Create New Template",
  createFunnelFromTemplate: "Create a new funnel from this template",
  dropImageMsg: "DROP OR UPLOAD AN IMAGE HERE",
  dropPdfMsg: "DROP OR UPLOAD PDF HERE",
  noIntegrationMsg: "No integration found!",
  noAssetMsg: "No Asset Found",
  noResults: "No Results",
  overlayMsg: "Is Loading",
  duplicateLoaderMessage: "Duplicate funnel...",
  leadServiceError: "Failed to update funnel lead service!",
  clipBoardNotAllowedMessage: "Please Allow clipboard permissions",
  delete: "Delete",
  notificationURLPlaceholder:
    "Enter the notification URL (you get this information from Zapier).",
  or: "or",
  title: "Title",
  addDomainModal: {
    title: "Domain und Subdomain hinzufügen",
    btnText: "Speichern & aus dem Archiv entfernen"
  },
  viewGalleryModal: {
    title: "Choose Picture",
    addImage: "Add New Image"
  },
  download: "Download",
  createNewCity: "Create New City",
  createNewCustomer: "Create New Customer",
  customerStats: {
    leads: "Leads",
    budget: "Budget"
  },
  createNewCategory: "Create New Ccategory",
  createFunnelModal: {
    title: "What type of {{type}} do you want to create?",
    actions: {
      lead: "Lead",
      employee: "Employee"
    }
  },
  createIntegrationTitle: "Create integration for this funnel",
  duplicateFunnelTitle: "Confirm duplication",
  duplicateFunnelMessage:
    "Do you want to <strong>duplicate</strong> this funnel?",
  duplicateFunnelMessageForFB:
    "Do you want to <strong>duplicate this funnel without facebook form</strong> details ?",
  archiveLeadMessage: `Do you really want to <strong class = "text-lowercase" >{{archive}}</strong> the lead?`,
  archiveFunnelMessage: "Do you want to <strong>archive</strong> this funnel?",
  deleteTemplateMessage: `Are you sure you want to <strong class="text-danger">delete</strong> this template?`,
  deleteImageMessage: `Are you sure you want to <strong class="text-danger">delete</strong> this image?`,
  deleteCategoryMessage: `Are you sure you want to <strong class="text-danger">delete</strong> this category?`,
  // deleteUserTokenMessage: `Are you sure you want to <strong class="text-danger" >delete</strong>  this user token?`,
  deleteCategorySuccess: "Category deleted successfully.",
  // deleteUserTokenSuccess: "User token deleted successfully.",
  clearAdDuplicatorFilesTitle: "Proceed with New Ad ID",
  clearAdDuplicatorFiles:
    "You have some pending ads with old Ad ID, Do you discard pending ads and proceed with new Ad ID?",
  modern: {
    selectFunnelDesign: "Select Funnel Design",
    backgroundColorAbove: "Background Color Above",
    backgroundColorBelow: "Background Color Below",
    headerLabelColor: "Telephone and email button",
    contrastColor: "Background color of the questions",
    taglineColor: "Right column top font color",
    taglineBackground: "Right column top background color",
    callLabel: "Displayed number",
    telephoneNumber: "Which phone number should you call?",
    emailAddress: "Email Address",
    headline: "At the top of the funnel heading",
    taglineOne: "Right column top heading",
    taglineTwo: "Right column top subtitle",
    formHeading: "Heading above name & email",
    infoLabelOne: "Left column headline entry form",
    infoLabelTwo: "Left column subtitle entry form",
    infoLabelThree: "Left column note about the entry form",
    uspLabel: "USP (Advantages / Benefits)",
    addNewUspLabel: "Add USP",
    colorLabel: "Color",
    iconLabel: "Icon",
    label: "Label",
    callToActionButtonHeading: "Call to action button",
    uspLabelPlaceholder: "USP-Label hinzufügen",
    deleteUspMessage: `Are you sure you want to <strong class="text-danger">delete</strong> this USP?`,
    deleteUspSuccess: "USP deleted successfully.",
    nextButton: {
      heading: "Next Button",
      btnTextColor: "Text Color",
      btnBackgroundColor: "Background Color",
      btnHoverBackground: "Hover Background"
    }
  },
  createFunnel: "Create Funnel",
  createUser: "Create User",
  // assignUserToken: "Assign User Token",
  duplicateAnswer: "Duplicate Answer",
  deleteAnswer: "Delete Answer",
  duplicateQuestion: "Duplicate Question",
  copyQuestion: "Copy Question",
  duplicatedQuestion: "Duplicated Question",
  pasteQuestion: "Paste Question",
  pasteQuestionSuccess: "Question pasted successfully",
  pasteQuestionError: "Question Id not recognized",
  clipboardCopySuccess: "{{what}} is copied to clipboard",
  deleteQuestion: "Delete Question",
  question_one: "Question",
  question_other: "Questions",
  endQuestionnaire: "End questionnaire",
  jumpToNextQuestion: "Jump to next question",
  jumptToQuestion: "Jump to question",
  jumptToOutcome: "Jump to outcome # {{num}}",
  addNewQuestion: "Add New Question",
  addNewOutcome: "Add New Outcome",
  addIcon: "Add Icon",
  changeIcon: "Change Icon",
  layout: "Layout",
  searchImage: "Search",
  backgroundImage: "Background Image",
  companyLogo: "Company Logo",
  companyLogoBG: "Company Logo Background",
  baseColor: "Base Color",
  footerTxtColor: "Footer Text Color",
  footerColors: {
    black: "Black",
    white: "White",
    whiteShadow: "White (Shadow)"
  },
  companyLogoScale: "Company Logo Scale",
  textElements: "Text Elements",
  nextButtonText: `"Next" Button Text`,
  nextButtonBG: `"Next" Button Background`,
  waitingScreen: "Waiting Screen",
  zipCodeScreen: "Zip Code Screen",
  formScreen: "Form Screen",
  fieldTitle: "Field Title",
  fieldPlaceholder: "Field Placeholder",
  firstAndLastName: "First & Last Name",
  forQuestions: "for any questions",
  submitButton: "Submit Button",
  getAnOffer: "Get an offer now",
  freeOfCharge: "free of charge & without obligation",
  numberCheck: "Number Check",
  phoneNumberVerification: "Phone number verification",
  thankyouPage: "Thankyou Page",
  textOnThankyouPage: "Text on",
  websiteBtnForThankyouPage: "Button for website on thankyou page",
  greetingPhrase: "Greeting Phrase",
  redirect: {
    switchLabel: "Redirect Lead",
    fieldLabel: "Redirect URL",
    fieldPlaceholder: "Enter redirect URL"
  },
  cancelEditing: "Cancel Editing",
  link: "Link",
  addNewCondition: "Add New Condition",
  addNewGroup: "Add New Group",
  enterHeading: "Enter Heading",
  msgPlaceholder:
    "Write a message that will be displayed if the user does not match the above criteria.",
  customerName: "Customer Name",
  customerInfo: "Customer Information",
  summary: "Summary",
  viewMore: "View More",
  view: "View",
  viewHere: "View here",
  categoryDescription: "Category description",
  leadPriceBenchmark: "Lead price Benchmark",
  leadQualifiedPriceBenchmark: "Qualified Lead price Benchmark",
  leadPriceCurrentText: "Facebook lead price (14 days)",
  leadQualifiedPriceCurrentText: "Qualified lead price (14 days)",
  enterCategoryDesc: "Enter category description",
  categoryForm: "Category Form",
  missingToken: "Token is missing!",
  domainArchivedMsg1:
    "This domain and subdomain is already used in an archived funnel!",
  domainArchivedMsg2: "This domain and subdomain is already in use!",
  domainAlreadyTakenMsg: `This <span class="fw-bold">domain and subdomain</span> is already taken, would you like to continue?`,
  useAnyway: "Use anyway",
  notAllowedDomainMsg: "You can't use this domain and subdomain",
  endDateWarning: "End date should be greater than start date...",
  minmaxRangeWarning: "Min value should be smaller than Max value...",
  wrongDate: "Wrong date...",
  backToLogin: "Back to Login",
  loginSuccessFull: "You have successfully logged in!",
  resetPassword: "Reset Password",
  generatePassword: "Generate Password",
  password: "Password",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  reset: "Reset",
  assetUpdateMsg: "Asset updated successfully!",
  uploadedOn: "Uploaded On",
  uploadImage: "Upload Image",
  fileType: "File Type",
  job: "Job",
  tonality: "Tonality",
  speech: "Speech",
  specialInstructions: "Special Instructions",
  open: "Open",
  views: "Views",
  contact: "Contact",
  completionRate: "Completion Rate",
  conversionRate: "Conversion Rate",
  noData: "No data to show",
  customerForm: "Customer Form",
  customerNumber: "Customer Number",
  contactDetails: "Contact Details",
  selectFbPage: "Select Facebook Page",
  selectUserAccount: "Select User Account",
  selectFbForm: "Select Facebook Form",
  facebookForm: "Facebook Form",
  formQuestions: "Form questions",
  userDetails: "User Details",
  pageDetails: "Page Details",
  formDetails: "Form Details",
  getPages: "Get Pages",
  retrievePagesNote:
    "No options available! Please fetch pages first to enable options.",
  tonalities: {
    Seriös: {
      title: "Serious",
      description:
        "Used for financial services, law firms, medical products and services. The focus is on trust and professionalism"
    },
    Lässig: {
      title: "Casual",
      description:
        "Suitable for lifestyle products, casual wear, or apps for young adults. It conveys a sense of relaxation and simplicity."
    },
    Humorvoll: {
      title: "Humorous",
      description:
        "A humorous tone can be used to capture the target audience's attention, such as for snack products, games, or advertising for movies."
    },
    Inspirierend: {
      title: "Inspiring",
      description:
        "Often used for sportswear, travel advertising, or non-profit organizations. It aims to motivate the viewer and convey a sense of admiration or aspiration."
    },
    Romantisch: {
      title: "Romantic",
      description:
        "Suitable for products like perfume, jewelry, or trips to romantic destinations. It generates feelings of intimacy and passion."
    },
    Sachlich: {
      title: "Factual",
      description:
        "This tone is direct and straightforward. It could be suitable for technical products, craft services, or educational offers."
    },
    Nostalgisch: {
      title: "Nostalgic",
      description:
        "This can be used to create a feeling of longing or memory, perhaps for retro products or brands with a long history."
    },
    Provokativ: {
      title: "Provocative",
      description:
        "Some brands choose a challenging or controversial tone to attract attention and provoke discussions"
    },
    Luxuriös: {
      title: "Luxurious",
      description:
        "For high-end products like designer clothing, luxury cars, or first-class travel services. The advertising emphasizes exclusivity and sophistication."
    },
    Pädagogisch: {
      title: "Educational",
      description:
        "Informs the viewer and conveys knowledge. This might be suitable for books, educational software, or scientific products."
    }
  },
  notSynced: "Not synced yet!",
  customAnswerMsg:
    "This <strong>type</strong> of question does not require answers.",
  campaign: "Campaign",
  facebookPgID: "facebook page id",
  questionnaire: "Questionnaire",
  currentVersion: "Current Version",
  activatePrimaryMessage: `Are you sure you want to <span class="fw-bold">activate</span> this user token?`,
  connectFbMessage: `Are you sure you want to <span class="fw-bold">revalidate</span> with facebook?`,
  versionDialogHeading:
    "Your new changes will interrupt Funnel Insight, do you want to archive your old funnel and save changes in a new version?",
  openProject: "Open Project",
  openAdvertisingAccount: "Open Advertising Account",
  openInTest: "Open in Test-Mode",
  script: "Script",
  funnelQA: {
    textOnly: "Text Only",
    freeText: "Free Text field",
    slider: "Slider",
    multi: "Multi Selected",
    single: "Single Selected",
    selectOption: "Select an option",
    questionPlaceholder: "Add Question Here"
  },
  funnelInsights: {
    leadService: {
      total: "Total Lead Service leads",
      open: "Open Leads",
      nameWrong: "Name Wrong Leads",
      numberWrong: "Number Wrong Leads",
      notReached: "Not Reached Leads",
      notSuitable: "Not Suitable Leads",
      suitable: "Suitable Leads"
    },
    websiteVisits: "Website Visits",
    phoneVisits: "Call attempt clicks",
    emailVisits: "Email button clicks",
    adEval: { totalBudget: "Total Budget Spent" }
  },
  sync: "Sync",
  forwardedOnContactCloud: "Forwarded on Contact Cloud",
  forwardedTooltips: {
    success: "The lead has been forwarded to the customer",
    pending: "The lead is currently still in telephone service",
    failed: "The lead is either not suitable or has not been reached"
  },
  multifactor: {
    emailAuth: "Email Authenticator",
    appAuth: "App Authenticator",
    scan: {
      heading: "Scan"
    },
    verify: {
      heading: "Verify Code",
      msg: "For changing the setting, please verify the authentication code:"
    },
    configure: {
      heading: "Configuring Google Authenticator or Authy",
      step1:
        "Install Google Authenticator (IOS - Android) or Authy (IOS - Android).",
      step2: `In the authenticator app, select " + " icon.`,
      step3: `Select "Scan a barcode (or QR code)" and use the phone's camera to scan this barcode.`
    },
    title: "Multi-Factor Authentication (MFA)",
    subtitle: "Secure your account with TOTP two-factor authentication.",
    setup: "Setup",
    btnText: "Verify & Activate",
    disableHeading: "Disable Two-Factor Authentication (2FA)",
    method: "Authenticator Method",
    sendCode: "Send Code",
    sendCodeMsg: "Send a verification code to",
    verificationMsg: `Verification code has been sent! If you didn't receive the code, try again in <strong>{{timer}}</strong> seconds`
  },
  common: {
    select: "Select",
    problem: "Problem",
    ignore: "Ignore",
    creative: "Creative",
    understood: "Understood",
    benchmark: "Benchmarck",
    difference: "Difference",
    action: "Action",
    security: "Security",
    generate: "Generate",
    addNew: "Add New",
    user: "User",
    user_other: "Users",
    logic: "Logic",
    headline: "Headline",
    product: "Product",
    job: "Job",
    enter: "Enter",
    import: "Import",
    template: "Template",
    template_other: "Templates",
    message: "Message",
    heading: "Heading",
    lead: "Lead",
    lead_other: "Leads",
    funnel: "Funnel",
    funnel_other: "Funnels",
    name: "Name",
    email: "Email",
    syncStatus: "Sync Status",
    update: "Update",
    confirmDeletion: "Confirm Deletion",
    yes: "Yes",
    no: "No",
    selectColor: "Select Color",
    create: "Create",
    source: "Source",
    leadSource: "Lead Source",
    role: "Role",
    firstName: "First Name",
    lastName: "Last Name",
    city: "City",
    country: "Country",
    new: "New",
    old: "Old",
    changePassword: "Change Password",
    enterEmail: "Enter Email",
    forgotPassword: "Forgot Password",
    send: "Send",
    login: "Login",
    logout: "Logout",
    alreadyTaken: "Already taken",
    isAlreadyTaken: "is already taken",
    categories: "Category Name",
    back: "Back",
    save: "Save",
    templateName: "Template Name",
    postalCode: "Postal Code",
    blockedPostalCode: "Blocked Postal Code",
    blockedOn: "Blocked on",
    search: "Search",
    type: "Type",
    of: "of",
    results: "Results",
    result: "Result",
    archive: "Archive",
    unarchive: "Unarchive",
    customer: "Customer",
    activeCampaignsOnly: "Active campaigns only",
    displayFunnelsWithFbLeadsOnly: "Display Funnels with Fb Leads Only",
    customer_other: "Customers",
    address: "Address",
    category: "Category",
    category_other: "Categories",
    form: "Form",
    answer: "Answer",
    companyInfo: "Company Information",
    companyName: "Company Name",
    companyWebsite: "Company Website",
    websiteBtnTxt: "Website button text",
    dataProtection: "Data Protection",
    imprint: "Imprint",
    createCustom: "Create Custom",
    telephone: "Telephone",
    preview: "Preview",
    saveFunnel: "Save Funnel",
    cancel: "Cancel",
    description: "Description",
    close: "Close",
    edit: "Edit",
    created: "Created",
    on: "on",
    company: "Company",
    createNew: {
      title: "Create new {{name}}"
    },
    // assignNew: {
    //   title: "Assign new {{name}}"
    // },
    enabled: "Enabled",
    openInNewTab: "Open in new tab",
    sortBy: "Sort By",
    sortOrder: "Sort Order",
    performanceReport: "Performance Report",
    invoices: "Invoices",
    deleted: "DELETED",
    change: "Change",
    confirm: "Confirm!"
  },
  monthsName: {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December"
  },
  missingInfo: {
    leadCount: "Lead Count",
    funnel: {
      selectCategory: "Select Category",
      selectCustomer: "Select Customer"
    },
    lead: {
      selectFunnel: "Select Funnel"
    }
  },
  advancedFilter: {
    title: "Advanced Filter",
    formType: "Form Type",
    search: "Search",
    archive: "Includes Archive",
    userStatus: "User Status",
    tags: "Tags",
    status: "Status",
    leadStatus: "Lead Status",
    funnel: "Funnel",
    priceRange: "Price Range",
    dateRange: {
      today: "Today",
      yesterday: "Yesterday",
      thisWeek: "This Week",
      lastWeek: "Last Week",
      thisMonth: "This Month",
      lastMonth: "Last Month",
      thisYear: "This Year",
      lastYear: "Last Year",
      last7D: "Last 7 days",
      last14D: "Last 14 days",
      last30D: "Last 30 days",
      last90D: "Last 90 days",
      custom: "Custom Date",
      days7: "7 Days",
      days14: "14 Days",
      days30: "30 Days"
    },
    fbSort: {
      leadCount: "Lead Count",
      costPerLead: "Cost Per Lead",
      click: "Click",
      reach: "Reach",
      impressions: "Impressions",
      costPerClick: "Cost Per Click",
      spend: "Spend",
      costPerQualified: "Cost per Qualified Lead",
      qualifiedCount: "Qualified Lead Count",
      leadQualify: "Lead Qualify",
      leadCost: "Lead Cost"
    },
    sortOrder: {
      highestFirst: "Highest first",
      lowestFirst: "Lowest first"
    },
    funnelType: "Funnel Type"
  },
  sidebar: {
    dashboard: "Dashboard",
    templates: "Templates",
    customers: "Customers",
    categories: "Categories",
    gallery: "Gallery",
    tags: "Tags",
    users: "Users",
    settings: "Settings",
    siteSettings: "Site Settings",
    profile: "Profile",
    leadAgent: "Lead Agent",
    advertisements: "Advertisements",
    adOptimization: "Optimizations"
  },
  funnelTable: {
    all: "All",
    leadCount: "Lead Count",
    lastChange: "Last Change",
    integrationStatus: "Integration Status"
  },
  templateTable: {
    lastModified: "Last Modified"
  },
  userSearchPlaceholder: "Search by name or email",
  searchByNamePlaceholder: "Search by Name",
  saveWithoutNewVersion: "Save without new version",
  leadAgent: {
    loadMore: "Load More",
    searchBy: "Search by",
    companyLocation: "Company Location",
    distanceToCustomer: "Distance to Customer",
    calcDistance: "Calculate Distance",
    postalCodeMissingMsg: "Postal code is missing",
    callInProgress: "Call Inprogress",
    callContact: "Call Contact",
    calling: "Calling...",
    sending: "Sending...",
    completing: "Completing...",
    complete: "Complete",
    wrongNum: "Wrong Number",
    notReached: "Not Reached",
    wrongName: "Name Wrong",
    sendContactToCustomer: "Send contact to customer",
    leadInfo: "Lead Information",
    interestedParty: "Interested Party",
    enterValue: "Enter Value",
    dateOfRegistration: "Date of registration",
    speedDial: "Speed Dial",
    availableDaily: "Available Daily",
    enterComment: "Enter comment",
    qualified: "Qualified",
    notSuitable: "Not Suitable",
    appointmentWitHtheCustomer: "Make an appointment with the customer",
    appointmentInCalendy: "Book an appointment in Calendy",
    noLeads: "No lead to show",
    already: "Already",
    successfullyQualified: "Successfully qualified.",
    notQualifiedYet: "Not qualified yet.",
    available: "Available",
    after: "After",
    callAnyway: "Call anyway",
    interactingWithAnotherAgent: "Interacting with",
    leadServicePlusWarning:
      "Attention: This lead is processed with the Leadservice+",
    notes: "NOTES",
    notesPlaceholder: "Add a note or comment for the customer...",
    emailsToSendHeading: "Emails To Send",
    alreadyTakenMsg: "This Lead has been taken by another agent!",
    allOpenLeadsContactedMsg:
      "All open leads contacted by Funnel <strong>{{funnelName}}</strong>",
    noLeadsYetTooltip: "No leads yet!",
    backToNormalBtn: "Back To Normal",
    contactLater: "Contact lead later",
    contactLaterDialogHeading: "Date For Contact Later"
  },
  leadActivities: {
    title: "Activities",
    noActivityMsg: "No activities for this lead till now!",
    noteTooltip: "Note",
    agentInfo: "Agenten Info",
    // enums
    type: {
      CALL: "Call",
      SMS: "SMS",
      EMAIL: "Email",
      RESET: "Reset",
      SYNC_MANUALLY: "Manual Sync",
      PARTIAL_SYNC_MANUALLY: "Manual Sync Partially"
    },
    resultType: {
      NUMBER_WRONG: "Number incorrect",
      NAME_WRONG: "Name incorrect",
      COMPLETED: "Data correct",
      CALL: "Call",
      QUALIFIED: "Lead is qualified",
      NOT_SUITABLE: "Lead is not suitable",
      NOT_REACHED: "Not reached",
      RESET_SUCCESS: "Reset by admin",
      SYNCED_MANUALLY: "Manually Synced",
      CONTACT_LATER: "Contact lead later"
    }
  },
  adEvaluation: {
    creativeOverview: "Creative Overview",
    leadCount: "Number of leads",
    leadCost: "Cost per lead",
    budgetSpent: "Budget spent",
    qualified: "Qualified leads",
    suggestionGallery: "Suggestion Gallery",
    adLabel: "Ad Name",
    adsetLabel: "Adset Name",
    titlesLabel: "Titles",
    descriptionsLabel: "Descriptions",
    bodiesLabel: "Bodies",
    expandBtnLabel: "Expand"
  },
  funnelsEvaluation: "Funnels Evaluation",
  creativeDashboard: "Creative Dashboard",
  funnelDetailsOverview: "Funnel Details Overview",

  invalidIdErrorMessage: "Invalid ID",
  sendBackToAgentTitle: "Send this lead back to lead agent",
  sendBackToAgentMessage: `Do you really want to send this lead back to <strong>lead agent</strong>?`,
  tools: "Tools",
  linkedFunnels: "Linked Funnels",
  pricePerInvoiceUnitLabel: "Price per invoice unit",
  unitLabel: "Unit",
  preventReloadWarning: "Leave website? Your changes were not saved!",
  downloadAsCsv: "Download as CSV",
  suggestionGallery: {
    currentTabLabel: "Current Suggestions",
    upload: "Upload",
    uploadDialogHeading: "Upload new item for suggestion",
    uploadImage: "Upload Image",
    dropHere: "Drop Here",
    adLink: "Ad link",
    count: "Count",
    testedLinksButtonText: "The image has already been tested here",
    testedLinks: "Tested Links",
    addNewTestedLink: "Add new tested link",
    uploadedSuccessfully: "Uploaded Successfully!",
    noteLabel: "Note",
    linkToCampaignLabel: "Link to Campaign",
    linkToFunnelLabel: "Link to Funnel",
    archiveModal: {
      heading: "{{what}} Suggestion",
      msg: `Are you sure you want to <strong class="text-danger">{{what}}</strong> this suggestion?`,
      multiHeading: "{{what}} Suggestions",
      multiMsg: `Are you sure you want to <strong class="text-danger">{{what}}</strong> these suggestions?`
    },
    winningModal: {
      mark: "mark",
      remove: "remove",
      msg: `Are you sure you want to {{what}} this suggestion as winning one?`
    }
  },
  leadservicePanel: {
    onHoldHeading: "On Hold",
    onHoldInfoMsg: "Leads on hold",
    currentlyDisabledWarning: "Leadservice of this Funnel is currently disabled"
  },
  adLinkPlaceholder: "Insert link to advertising account",
  uploadScriptDialog: {
    uploadPDF: "Upload PDF"
  },
  textElementsTelephoneCheck: {
    switchLabel: "Enable telephone number with two steps",
    screenHeadline: "Telephone Screen Headline"
  },
  textElementsImageCheck: {
    switchLabel: "Enable Form Image",
    fieldLabel: "Form Image",
    placementRadioLabel: "Form Image Placement",
    radioBeforeLabel: "Before Form Subheadline",
    radioAfterLabel: "After Form Subheadline"
  },
  leadTabs: {
    leads: "Leads",
    incompleteLeads: "Incomplete Leads",
    numberOfEmailsSentLabel: "Number of Emails sent"
  },
  policySection: {
    switchLabel: "Display Policy Section",
    toBeShownTooltip: "Policy Section will be shown",
    notToBeShownTooltip: "Policy Section will not be shown",
    textFieldPlaceholder: "Enter text for display as policy text"
  },
  incompleteLeadDialog: {
    title: "Delete Incomplete Lead",
    msg: "Do you want to <strong>delete</strong> this?"
  },
  editorModal: {
    deleteConfirmationMsg:
      "Are you sure you want to <strong>delete</strong> the {{what}}?"
  },
  preLandingPage: {
    duplicateLabel: "Duplicate",
    duplicateSubLabel: "Duplicate pre landing page template to any funnel.",
    duplicateSuccessMsg: `Pre landing page for Funnel {{funnelName}} duplicated successfully!`,
    duplicateDisabledTooltip: "Your changes are not saved!",
    sameFunnelWarning: "You are currently standing on same funnel",
    enableSwitchLabel: "Enable Landing Page",
    enableSwitchDialogMsg: `Are you sure you want to <strong class="text-danger">enable</strong> landing page?`,
    disableSwitchDialogMsg: `Are you sure you want to <strong class="text-danger">disable</strong> landing page?`,
    selectTemplateLabel: "Select Template",
    templateOneOptLabel: "Template 1 (Image/Text - one below the other)",
    templateTwoOptLabel: "Template 2 (Image/Text - side by side)",
    deletePointMessage: `Are you sure you want to <strong class="text-danger">delete</strong> this Point?`,
    deletePointSuccess: "Hero Content Point deleted successfully.",
    deleteBenefitMessage: `Are you sure you want to <strong class="text-danger">delete</strong> this Benefit?`,
    deleteBenefitSuccess: "Benefit deleted successfully."
  },
  preLandingPageOne: {
    headerBgColorLabel: "Header Background Color",
    headlineTextLabel: "Headline Text",
    headlineColorLabel: "Headline Color",
    heroHeadlineTextLabel: "Hero Headline Text",
    heroHeadlineTextColorLabel: "Hero Headline Text Color",
    heroContentLabel: "Hero Content",
    heroImageLabel: "Hero Image",
    ctaButtonTextLabel: "CTA Button Text",
    ctaButtonTextColorLabel: "CTA Button Text Color",
    ctaButtonBackgroundColor: "CTA Button Background Color",
    benefits: {
      benefitsGridHeadline: "Benefits Grid Headline",
      benefitsGridTextColor: "Benefit Text Color",
      benefitsGridSubHeadline: "Benefits Grid Sub Headline",
      benefitsGridLabel: "Benefits Grid",
      addNewBenefitBtn: "Add benefit",
      label: "Label",
      subtext: "Subtext",
      icon: "Icon",
      selectBtn: "Select"
    },
    footerContent: "Footer Content"
  },
  preLandingPageTwo: {
    backgroundImageLabel: "Background Image",
    centerContentBackgroundColorLabel: "Center Content Background Color",
    centerContentBackgroundOpacityLabel: "Center Content Background Opacity",
    contactUsButtonTextLabel: "Contact Us Button Text",
    contactUsButtonColorLabel: "Contact Us Button Color",
    contactUsButtonBackgroundHoverLabel: "Contact Us Button Background Hover",
    phoneNumberHeadlineLabel: "Phone Number Headline",
    phoneNumberTextLabel: "Phone Number Text",
    phoneNumberColorLabel: "Phone Number Color",
    subHeadlineTextLabel: "Sub-Headline Text",
    subHeadlineColorLabel: "Sub-Headline Color",
    heroContentLabel: "Hero Content",
    heroImageLabel: "Hero Image",
    heroPoints: {
      headingLabel: "Bullet Points Next To Hero Image (maximum 3)",
      btnLabel: "Add Bullet Points next to image",
      label: "Label",
      text: "Text"
    },
    footerMapLabel: "Footer Embedded Map URL",
    heroContentTextColorLabel: "Hero Content Text Color",
    heroContentIconColorLabel: "Hero Content Icon Color"
  },
  versionDialogMsg: {
    changeVersionAndLandingPage:
      "<div>Landing page in this version is <strong>different</strong> from current version.</div><div>Do you want to continue and <strong>update</strong> the landing page?</div>",
    changeVersionAndDisableLandingPage:
      "<div>There is no pre-landing page linked in this version.</div><div>Should we <strong>deactivate</strong> the pre-landing page anyway?</div>",
    changeVersionAndEnableLandingPage:
      "<div>There is active pre-landing page linked in this version.</div><div>Should we <strong>activate</strong> the pre-landing page anyway?</div>",
    default: `Are you sure you want to <strong>activate</strong> this funnel version?`
  },
  deleteIntegration: "Delete Integration",
  disableLeadServiceModal: {
    heading: "Disable Lead Service",
    associatedIntegrationMsg: `This Lead Service is associated with <strong>{{count}} Leads</strong> that needs to be synced`,
    syncNowAndDisable: "Sync Now & Disable",
    disableNowAndSyncLater: "Disable Now & Sync Later"
  },
  lastTaskLabel: "Last Task"
};
export const DE = {
  adName: "Bild",
  averageCalls: "Durchschnittliche Anrufe",
  funnelFilters: {
    multiPCHeading: "Einzubeziehende Standorte",
    multiPCCaption: "Zielen Sie auf mehrere Postleitzahlen",
    multiPCPlaceHolder: "Geben Sie die Postleitzahlen zeilenweise getrennt ein",
    clearAll: "alles löschen",
    addLocations: "Standorte hinzufügen",
    germany: "Deutschland",
    switzerland: "Schweiz",
    austria: "Österreich"
  },
  leadpannel: {
    leadCountTitle: "Leadservice Kennzahlen",
    leadRateTitle: "Lead-Rate",
    syncedLeads: "Synchronisierte Leads",
    unsyncedLeads: "Nicht synchronisierte Leads",
    totalLeads: `Gesamtzahl <br /> Leads`
  },
  invoice: {
    heading: "Rechnungen",
    invoiceNumber: "Rechnungsnummer",
    invoiceDate: "Rechnungsdatum",
    invoiceAmount: "Rechnungsbetrag",
    download: "Herunterladen",
    downloading: "Herunterladen...",
    preview: "Vorschau",
    opening: "Öffnung...",
    sendToCust: "Senden an Kunde",
    sending: "Sendet...",
    noInvoices: "Keine Rechnungen Gefunden",
    noInvoicesForYear: "Für dieses Jahr wurden keine Rechnungen gefunden"
  },
  toastMessage: {
    templateAppliedSuccess: "Vorlage angewendet",
    disable2FAError: "Fehler beim Deaktivieren von 2FA!",
    verificationError: "Fehler bei der Verifizierung!",
    qrCodeError: "Fehler beim Erstellen des QR-Codes!",
    updateFBToken: "Fehler beim Aktualisieren des FB-Tokens!",
    selectAssetError: "Fehler bei der Auswahl des Assets!",
    funnelByDomain: "Fehler beim Laden des Funnels durch Domain & Sub-Domain!",
    updateLeadError: "Fehler beim Aktualisieren der Lead-Daten!",
    update: "Fehler beim Aktualisieren!",
    duplicateFunnelError: "Fehler beim Duplizieren des Trichters!",
    duplicateFunnelSuccess: "Funnel erfolgreich dupliziert!",
    duplicateFunnelWarning:
      "Dieser Funnel kann nicht dupliziert werden, da er unvollständige Informationen enthält!",
    noTenantIdWarning:
      "Es gibt keine Tenant-ID, du musst die Contact-Cloud-ID zu diesem Kundenfunnel hinzufügen!",
    createAssetError: "Fehler beim Erstellen des Assets!",
    createUserError: "Fehler beim Erstellen des Benutzers!",
    // assignUserTokenError: "Fehler beim Zuweisen des Benutzertokens!", // Translated by google
    fetchUsersError: "Fehler beim Abrufen der Benutzer!",
    updateUserError: "Fehler beim Aktualisieren des Benutzers!",
    downloadError: "Fehler beim Herunterladen des Bildes!",
    uploadItemError: "Fehler beim Hochladen des Artikels!",
    availableVersion: "Fehler beim Suchen nach verfügbarer Version!",
    importFunnel: "Fehler beim Importieren des Trichters!",
    createTemplateError: "Fehler beim Erstellen der Vorlage!",
    archiveError: "Fehler im Archiv!",
    unarchiveError: "Fehler beim Entarchivieren!",
    assetsUploadError: "Fehler beim Hochladen von Assets",
    fbPagesError: "Fehler beim Abrufen der Facebook-Seiten!",
    draftFunnel: "Fehler beim Erstellen des Draft-Trichters!",
    draftTemplate: "Fehler beim Erstellen der Entwurfsvorlage!",
    deleteTemplate: "Fehler beim Löschen der Vorlage!",
    fbFormError: "Fehler beim Abrufen von Facebook-Seitenformularen!",
    wentWrong: "Etwas ist schief gelaufen!",
    categoryByName: "Kategorie nach Namen!",
    categoryByID: "Kategorie nach ID!",
    saveCategory: "Fehler beim Speichern der Kategorie!",
    changePassword: "Fehler beim Ändern des Passworts!",
    fetchInsightsError: "Fehler beim Abrufen von Erkenntnissen",
    saveCustomer: "Fehler beim Speichern des Kunden!",
    createCity: "Fehler beim Erstellen einer neuen Stadt!",
    updateFunnels: "Fehler beim Aktualisieren des Funnels!",
    updateTemplate: "Fehler beim Aktualisieren der Vorlage!",
    errorInGetting: "Fehler beim Erhalten von {{what}}!",
    errorInUpdating: "Fehler beim Aktualisieren von {{what}}!", // Translated by google
    updateFunnelFilters: "Fehler beim Aktualisieren der Trichterfilter",
    removeDomain: "Fehler beim Entfernen der Domain und Subdomain",
    fetchVersion: "Fehler beim Abrufen der Version!",
    changeVersion: "Fehler beim Versionswechsel!",
    downloadSuccessfully: "Erfolgreich Herunterladen!",
    csvDownloadError: "Fehler beim herunterladen csv!"
  },
  fbTokenInfoMessage:
    "Derzeit wird der Facebook-Token von <strong>{{what}}</strong> verwendet",
  availableAfter: "Nach wie vielen Stunden sind die Anfragen wieder verfügbar",
  createdLeads: "Anzahl erstellten Leads",
  numberOfVisits: "Anzahl der Besuche",
  outcome: {
    jumpDirect: "Direkt zu diesem Outcome springen",
    jumpEnd: "Erst am Ende zu diesem Outcome springen",
    restart: "Fragebogen neu starten"
  },
  settings: {
    welcome: "Willkommen in den Profileinstellungen:",
    connectFB: "Mit Facebook verbinden"
  },
  funnelIntegration: {
    tabTitleActiveTooltip: "Hat aktiv integrations",
    tabTitleNotIntegratedTooltip: "Nicht integrated",
    error: "Fehler bei der Integration!",
    updateIntegration: "Fehler beim Aktualisieren der Integration!",
    removeIntegration: "Fehler beim Entfernen der Integration!",
    descriptionPlaceholder: "Integrationsbeschreibung hier hinzufügen",
    deleteIntegrationWarning: `Möchten Sie diese Integration <strong>löschen</strong>?`,
    deleteAppointmentLinkWarning: `Möchten Sie des treffen <strong>löschen</strong>?`,
    deleteAccountLinkWarning: `Möchten Sie diese werbekonto verknüpfung <strong>löschen</strong>?`,
    webhookPlaceholder:
      "Webhook-URL eingeben (diese Information bekommst du von Zapier)",
    updateToastMsg: "Integration erfolgreich aktualisiert!",
    enableDialogMsg:
      "Möchten Sie diese integration <strong>aktivieren</strong>?",
    disableDialogMsg:
      "Möchten Sie diese integration <strong>deaktivieren</strong>?",
    sendDataHeading: "Daten Senden",
    selectOptions: {
      never: "Nie",
      immediately: "Sofort senden",
      afterConfirmation: "Nach Bestätigung senden",
      leadServiceInfo: "Aktivieren Lead Service 2.0 zuerst!"
    },
    leadService: {
      enableDialogMsg:
        "Möchten Sie Lead Service 2.0 <strong>aktivieren</strong>?",
      disableDialogMsg:
        "Möchten Sie Lead Service 2.0 <strong>deaktivieren</strong>?",
      warningDialogMessage: `Wenn du den Lead-Service 2.0 deaktivierst, werden deine Einstellungen für das Senden von <strong>E-Mails</strong> an Kunden, <strong>contact cloud</strong> oder <strong>zapier</strong> integration beeinträchtigt!<div>Möchtest du fortfahren?</div>`,
      enablePlusMsg: "Möchten Sie Lead Service+ <strong>aktivieren</strong>?",
      disablePlusMsg:
        "Möchten Sie Lead Service+ <strong>deaktivieren</strong>?",
      leadServiceCompanyNameLabel: "Leadservice Unternehmensname",
      leadServiceProductNameLabel: "Produktname"
    },
    leadServicePlus: {
      priceTypeLabel: "Preistyp",
      calculationLabel: "Berechnung",
      fixedPriceLabel: "Festpreis",
      areaQueryLabel: "Bereichsabfrage",
      pricePerInvoiceUnitLabel: "Preis pro Rechnungseinheit",
      fixedPricePerInvoiceUnitLabel: "Festpreis pro Rechnungseinheit",
      nameOfAccountingUnitLabel: "Name Rechnungseinheit",
      specifyHerePlaceholder: "Hier angeben",
      budgetQueryLimitsLabel: "Grenzen für die Budgetabfrage",
      pricePerAreaLabel: "Preis pro Bereich",
      disabledBudgetLimitRadioMsg:
        "Hinzufügen Name Rechnungseinheit zu Aktivieren",
      removeUnitNameConfirmationMsg: `Wenn du den <strong>Name Rechnungseinheit</strong> entfernen, werden deine <strong>Preistyp</strong> für das <strong>Bereichsabfrage</strong> beeinträchtigt!<div>Möchtest du fortfahren?</div>`
    },
    alternativeAddress: {
      checkboxLabel: "Abweichende Adresse",
      error: "Fehler beim Aktualisieren des Abweichende Adresse!"
    },
    email: {
      alertDialogMessage: `Entschuldigung, du musst zuerst den <strong>Lead-Service 2.0</strong> aktivieren!`,
      label: "E-Mail an Kunden senden",
      toastError:
        "Fehler beim Aktualisieren der E-Mail-Konfiguration für Kunden!"
    },
    slack: {
      checkboxLabel: "Slack Notifications",
      fieldLabel: "Notification-URL",
      fieldPlaceholder:
        "Notification-URL eingeben (diese Information bekommst du von Slack).",
      toastError: "Fehler im slack integration"
    },
    contactCloud: {
      connectBtn: "Verbinden",
      contactCloudSetup: "Contact Cloud Einrichtung",
      optionsError: {
        users: "benutzeroptionen",
        products: "produktoptionen",
        jobs: "job optionen"
      }
    },
    appointment: {
      heading: "Kundentermin",
      label: "Treffen verknüpfung",
      placeholder: "Treffen verknüpfung hinzufügen"
    },
    advertisingAccount: {
      heading: "Werbekonto",
      label: "Werbekonto Verknüpfung",
      placeholder: "Eingeben werbekonto verknüpfung"
    }
  },
  modalContent: {
    saveAsTemplate: "Als{{new}}Vorlage speichern",
    deleteOutcome: `Bist Du Dir sicher, dass Du dieses Ergebnis <span class="text-danger">löschen</span> möchten? 
    <div> Weil es als Antwortaktion in <span class="text-danger fw-bold">funnel QA</span> </div>`,
    deleteFunnel: `Sind Sie sicher, dass Sie diese Frage <span class="text-danger">löschen</span> möchten?`,
    responseAction: "Weil es als Antwortaktion in",
    funnel: {
      unsavedWarning:
        "Sie haben ungespeicherte Änderungen. Speichern Sie diese, um als Vorlage zu speichern",
      unarchiveFunnelTitle: "Funnel aus dem Archiv entfernen",
      unarchiveFunnelMsg: `Möchten Sie diesen Funnel <span class="fw-bold">aus dem Archiv entfernen?</span>`,
      note: "Dieser Funnel hat keine Domain & Subdomain!",
      discardUnsavedWarning: `Ihre ungespeicherten Funnels werden <strong>verworfen</strong>. Möchten Sie fortfahren?`
    }
  },
  adCopy: {
    title: "Anzeigentext",
    noAdMessage: "Keine Anzeige extrahiert",
    dataCompleted: "Trainingsdaten vervollständigt",
    emojis: "Emojis",
    funnelURL: "Funnel-URL"
  },
  adDuplicator: {
    fetchAdAcc: "Anzeigenkonten abrufen",
    fetchAd: "Anzeige abrufen",
    pasteAndFetchAd: "Anzeige einfügen und abrufen",
    uploadAdsToFb: "Laden Sie Anzeigen auf Facebook hoch",
    clearAllAds: "Alles löschen",
    title: "Anzeigenduplizierer",
    viewIdLabel: "Anzeigen-ID",
    imgStatus: "Bildstatus",
    adStatus: "Anzeigenstatus",
    disabledDropImageMsg: "Überprüfen Sie die hochzuladende Anzeige",
    adUploadingStatus: {
      waiting: {
        status: "BEREIT ZUM ERSTELLEN",
        message: "Ich warte darauf, eine Facebook-Anzeige zu erstellen."
      },
      uploading: {
        status: "HOCHLADEN...",
        message: "Anzeige auf Facebook erstellen."
      },
      success: { status: "ERFOLG", message: "Anzeige erfolgreich erstellt." },
      rejected: { status: "ABGELEHNT" }
    }
  },
  basics: "Grundlegendes",
  leadSyncMsg:
    "Lead ist bereits synchronisiert. Möchtest Du erneut <strong>synchronisieren</strong>?",
  passwordChangeMsg: "Passwort erfolgreich geändert!",
  overview: "Übersicht",
  infoDetails: "Info-Details",
  hubspotID: "Hubspot ID",
  hubspotProjectID: "Hubspot Projekt ID",
  contactCloudID: "Contact Cloud ID",
  createNewFunnel: "Neuen Funnel erstellen",
  updateTemplate: "Aktualisieren Vorlage",
  createTemplate: "Neue Vorlage erstellen",
  createFunnelFromTemplate: "Erstelle einen neuen Funnel aus dieser Vorlage",
  dropImageMsg: "ZIEHE EIN BILD HIERHER ODER LADE EINS HOCH",
  dropPdfMsg: "ZIEHE EIN PDF HIERHER ODER LADE EINS HOCH",
  noIntegrationMsg: "Keine Integration gefunden!",
  noAssetMsg: "Kein Asset gefunden",
  noResults: "Keine Ergebnisse",
  overlayMsg: "Wird geladen",
  duplicateLoaderMessage: "Funnel duplizieren...",
  leadServiceError: "Fehler beim Aktualisieren des Funnel-Leadservices!",
  clipBoardNotAllowedMessage:
    "Bitte erlauben Sie die Berechtigung für die Zwischenablage",
  delete: "Löschen",
  notificationURLPlaceholder:
    "Notification-URL eingeben (diese Information bekommst du von Zapier).",
  or: "oder",
  title: "Titel",
  addDomainModal: {
    title: "Add domain and subdomain",
    btnText: "Save and remove from archive"
  },
  viewGalleryModal: {
    title: "Bild auswählen",
    addImage: "Neues Bild hinzufügen"
  },
  download: "Herunterladen",
  createNewCity: "Neuen Stadt erstellen",
  createNewCustomer: "Neuen Kunde erstellen",
  customerStats: {
    leads: "Führt",
    budget: "Budget"
  },
  createNewCategory: "Neuen Kategorie erstellen",
  createFunnelModal: {
    title: "Welche Art {{type}} möchtest du erstellen?",
    actions: {
      lead: "Neukunden",
      employee: "Mitarbeiter"
    }
  },
  createIntegrationTitle: "Integration für diesen Funnel anlegen",
  duplicateFunnelTitle: "Duplikation bestätigen",
  duplicateFunnelMessage:
    "Möchten Sie diesen Funnel <strong>duplizieren</strong>?",
  duplicateFunnelMessageForFB:
    "Möchten Sie diesen Funnel ohne Facebook-Formulardetails <strong>duplizieren</strong>?",
  archiveLeadMessage: `Möchtest du den Lead wirklich <strong class = "text-lowercase" >{{archive}}</strong>`,
  archiveFunnelMessage:
    "Möchtest du den Funnel wirklich <strong>archivieren</strong>?",
  deleteTemplateMessage: `Sind Sie sicher, dass Sie diese Vorlage <strong class="text-danger">löschen</strong> möchten?`,
  deleteImageMessage: `Sind Sie sicher, dass Sie diese Bild <strong class="text-danger">löschen</strong> möchten?`,
  deleteCategoryMessage: `Sind Sie sicher, dass Sie diese Bild <strong class="text-danger">löschen</strong> dieser Kategorie?`,
  // deleteUserTokenMessage: `Sind Sie sicher, dass Sie diese Bild <strong class="text-danger" >löschen</strong>  dieser Benutzertoken?`, // Translated by google
  deleteCategorySuccess: "Kategorie erfolgreich gelöscht.",
  // deleteUserTokenSuccess: "Benutzertoken erfolgreich gelöscht.", // Translated by google
  clearAdDuplicatorFilesTitle: "Fahren Sie mit der neuen Anzeigen-ID fort",
  clearAdDuplicatorFiles:
    "Sie haben einige ausstehende Anzeigen mit der alten Anzeigen-ID. Verwerfen Sie ausstehende Anzeigen und fahren Sie mit der neuen Anzeigen-ID fort?",
  modern: {
    selectFunnelDesign: "Funnel-Design auswählen",
    backgroundColorAbove: "Hintergrund-Farbe oben",
    backgroundColorBelow: "Hintergrund-Farbe unten",
    headerLabelColor: "Telefon und Email Button",
    contrastColor: "Hintergrundfarbe der Fragen",
    taglineColor: "Rechte Spalte Oben Schriftfarbe",
    taglineBackground: "Rechte Spalte Oben Hintergrundfarbe",
    callLabel: "Angezeigte Nummer",
    telephoneNumber: "Welche Telefonnummer soll angerufen werden?",
    emailAddress: "E-Mail Adresse",
    headline: "Ganz oben Funnel Überschrift",
    taglineOne: "Rechte Spalte Oben Überschrift",
    taglineTwo: "Rechte Spalte Oben Subtitel",
    formHeading: "Überschrift über Name & Email",
    infoLabelOne: "Linke Spalte Headline Eintragungsformular",
    infoLabelTwo: "Linke Spalte Subtitel Eintragungsformular",
    infoLabelThree: "Linke Spalte Hinweis zum  Eintragungsformular",
    uspLabel: "USP (Vorteile / Benefits)",
    addNewUspLabel: "USP hinzufügen",
    colorLabel: "Farbe",
    iconLabel: "Icon",
    label: "Beschriftung",
    callToActionButtonHeading: "Call-to-Action-Schaltfläche",
    uspLabelPlaceholder: "USP-Label hinzufügen",
    deleteUspMessage: `Sind Sie sicher, dass Sie diese USP <strong class="text-danger">löschen</strong> möchten?`,
    deleteUspSuccess: "USP erfolgreich gelöscht",
    nextButton: {
      heading: "Weiter Button",
      btnTextColor: "Text-Farbe",
      btnBackgroundColor: "Hintergrund-Farbe",
      btnHoverBackground: "Hover Hintergrund-Farbe"
    }
  },
  createFunnel: "Funnel erstellen",
  createUser: "Benutzer erstellen",
  duplicateAnswer: "Antwort duplizieren",
  deleteAnswer: "Antwort löschen",
  duplicateQuestion: "Frage duplizieren",
  copyQuestion: "Frage kopieren",
  duplicatedQuestion: "Doppelte Frage",
  pasteQuestion: "Frage einfügen",
  pasteQuestionSuccess: "Frage erfolgreich eingefügt",
  pasteQuestionError: "Frage-ID nicht erkannt",
  clipboardCopySuccess: "{{what}} wird die Zwischenablage kopiert",
  deleteQuestion: "Frage löschen",
  question_one: "Frage",
  question_other: "Fragen",
  endQuestionnaire: "Fragebogen beenden",
  jumpToNextQuestion: "Zur nächsten Frage springen",
  jumptToQuestion: "Springe zu Frage",
  jumptToOutcome: "Zur Outcome # {{num}} springen",
  addNewQuestion: "Neue Frage hinzufügen",
  addNewOutcome: "Neues Outcome hinzufügen",
  addIcon: "Symbol hinzufügen",
  changeIcon: "Symbol ändern",
  layout: "Gestaltung",
  searchImage: "Durchsuchen",
  backgroundImage: "Hintergrundbild",
  companyLogo: "Firmenlogo",
  companyLogoBG: "Hintergrund des Firmenlogos",
  baseColor: "Grundfarbe",
  footerTxtColor: "Textfarbe des Fußbereichs",
  footerColors: {
    black: "Schwarz",
    white: "Weiß",
    whiteShadow: "Weiß (Schatten)"
  },
  companyLogoScale: "Skalierung des Firmenlogos",
  textElements: "Text-Elemente",
  nextButtonText: `Text des "Weiter" Buttons`,
  nextButtonBG: `Hintergrund des "Weiter" Buttons`,
  waitingScreen: "Warte-Screen",
  zipCodeScreen: "Postleitzahl-Screen",
  formScreen: "Formular-Screen",
  fieldTitle: "Feldtitle",
  fieldPlaceholder: "Feld-Placeholder",
  firstAndLastName: "Vor- & Nachname",
  forQuestions: "für eventuelle Rückfragen",
  submitButton: "Absenden-Button",
  getAnOffer: "Jetzt Angebot erhalten",
  freeOfCharge: "kostenlos & unverbindlich",
  numberCheck: "Nummernprüfung",
  phoneNumberVerification: "Telefonnummer Überprüfung",
  thankyouPage: "Danke-Seite",
  textOnThankyouPage: "Text auf",
  websiteBtnForThankyouPage: "Button zur Webseite auf Dankeseite",
  greetingPhrase: "Grußsatz",
  redirect: {
    switchLabel: "Leite Lead um",
    fieldLabel: "Leite URL um",
    fieldPlaceholder: "Gib die Umleitungs-URL ein"
  },
  cancelEditing: "Bearbeitung abbrechen",
  link: "Verknüpfen",
  addNewCondition: "Neue Bedingung hinzufügen",
  addNewGroup: "Neue Gruppe hinzufügen",
  enterHeading: "Überschrift eingeben",
  msgPlaceholder:
    "Schreiben Sie eine Nachricht, die angezeigt wird, wenn der Benutzer nicht den oben genannten Kriterien entspricht.",
  customerName: "Kundenname",
  customerInfo: "Kundeninformationen",
  summary: "Zusammenfassung",
  viewMore: "Mehr anzeigen",
  view: "Anzeigen",
  viewHere: "Hier ansehen",
  categoryDescription: "Kategoriebeschreibung",
  leadPriceBenchmark: "Leadpreis Benchmark",
  leadQualifiedPriceBenchmark: "Qualifizierter Leadpreis Benchmark",
  leadPriceCurrentText: "Facebook Leadpreis (14 Tage)",
  leadQualifiedPriceCurrentText: "Qualifizierter Leadpreis (14 Tage)",
  enterCategoryDesc: "Kategoriebeschreibung eingeben",
  categoryForm: "Kategorieformular",
  missingToken: "Token fehlt!",
  domainArchivedMsg1:
    "Diese Domain und Subdomain wird bereits in einem archivierten Funnel verwendet!",
  domainArchivedMsg2: "Diese Domain und Subdomain wird bereits verwendet!",
  domainAlreadyTakenMsg: `Diese <span class="fw-bold">Domain und Subdomain</span> ist bereits vergeben, möchtest Du fortfahren?`,
  useAnyway: "Trotzdem verwenden",
  notAllowedDomainMsg: "Du kannst diese Domain und Subdomain nicht verwenden",
  endDateWarning: "Enddatum sollte größer als Startdatum sein...",
  minmaxRangeWarning:
    "Der Mindestwert sollte kleiner sein als der Höchstwert...", // Translated by google
  wrongDate: "Falsches Datum...",
  backToLogin: "Zurück zur Anmeldung",
  loginSuccessFull: "Sie haben sich erfolgreich angemeldet!",
  resetPassword: "Passwort zurücksetzen",
  generatePassword: "Passwort generieren",
  password: "Passwort",
  newPassword: "Neues Passwort",
  confirmNewPassword: "Neues Passwort bestätigen",
  reset: "Zurücksetzen",
  assetUpdateMsg: "Asset erfolgreich aktualisiert!",
  uploadedOn: "Hochgeladen am",
  uploadImage: "Bild hochladen",
  fileType: "Dateityp",
  job: "Stélle",
  tonality: "Tonalität",
  speech: "Ansprache",
  specialInstructions: "Spezielle Anweisungen",
  open: "Öffnen",
  views: "Aufrufe",
  contact: "Kontakte",
  completionRate: "Abschlussquote",
  conversionRate: "Konvertierungsrate",
  noData: "Keine Daten zum Anzeigen",
  customerForm: "Kundenformular",
  customerNumber: "Kundennummer",
  contactDetails: "Kontaktdetails",
  selectFbPage: "Facebook-Seite auswählen",
  selectUserAccount: "Benutzerkonto auswählen",
  selectFbForm: "Facebook-Formular auswählen",
  formQuestions: "Formularfragen",
  userDetails: "Benutzerdetails",
  pageDetails: "Seitendetails",
  formDetails: "Formulardetails",
  getPages: "Seiten abrufen",
  retrievePagesNote:
    "Keine Optionen verfügbar! Bitte holen Sie zuerst Seiten, um Auswahlmöglichkeiten zu aktivieren.",
  tonalities: {
    Seriös: {
      title: "Seriös",
      description:
        "Verwendet für Finanzdienstleistungen, Anwaltskanzleien, medizinische Produkte und Dienstleistungen. Der Schwerpunkt liegt auf Vertrauen und Professionalität."
    },
    Lässig: {
      title: "Lässig",
      description:
        "Geeignet für Lifestyle-Produkte, Freizeitbekleidung oder Apps für junge Erwachsene. Es vermittelt ein Gefühl von Entspanntheit und Unkompliziertheit."
    },
    Humorvoll: {
      title: "Humorvoll",
      description:
        "Ein humorvoller Ton kann verwendet werden, um die Aufmerksamkeit der Zielgruppe zu gewinnen, etwa bei Snack-Produkten, Spielen oder Werbung für Filme."
    },
    Inspirierend: {
      title: "Inspirierend",
      description:
        "Oft verwendet für Sportbekleidung, Reisewerbung oder gemeinnützige Organisationen. Es soll den Betrachter motivieren und ein Gefühl der Bewunderung oder des Strebens vermitteln."
    },
    Romantisch: {
      title: "Romantisch",
      description:
        "Geeignet für Produkte wie Parfüm, Schmuck oder Reisen zu romantischen Zielen. Es erzeugt Gefühle von Intimität und Leidenschaft."
    },
    Sachlich: {
      title: "Sachlich",
      description:
        "Dieser Ton ist direkt und ohne Schnörkel. Er könnte für technische Produkte, Handwerksdienstleistungen oder Bildungsangebote geeignet sein."
    },
    Nostalgisch: {
      title: "Nostalgisch",
      description:
        "Dies kann verwendet werden, um ein Gefühl der Sehnsucht oder Erinnerung zu erzeugen, vielleicht für Retro-Produkte oder Marken mit einer langen Geschichte."
    },
    Provokativ: {
      title: "Provokativ",
      description:
        "Einige Marken wählen eine herausfordernde oder kontroverse Tonalität, um Aufmerksamkeit zu erregen und Diskussionen zu provozieren."
    },
    Luxuriös: {
      title: "Luxuriös",
      description:
        "Für High-End-Produkte wie Designerkleidung, Luxusautos oder erstklassige Reisedienste. Die Werbung betont Exklusivität und Raffinesse."
    },
    Pädagogisch: {
      title: "Pädagogisch",
      description:
        "Informiert den Betrachter und vermittelt Wissen. Dies könnte für Bücher, Bildungssoftware oder wissenschaftliche Produkte geeignet sein."
    }
  },
  notSynced: "Noch nicht synchronisiert!",
  customAnswerMsg:
    "Für diese <strong>Art</strong> von Frage sind keine Antworten erforderlich.",
  campaign: "Kampagne",
  facebookPgID: "Facebook-Seiten-ID eingeben",
  questionnaire: "Fragebogen",
  currentVersion: "Aktuelle Version",
  activatePrimaryMessage: `Sind Sie sicher, dass Sie dieses Benutzertoken <span class="fw-bold">aktivieren</span> möchten?`, // Translated by google
  connectFbMessage: `Sind Sie sicher, dass Sie sich mit Facebook <span class="fw-bold">erneut validieren</span> möchten?`, // Translated by google
  versionDialogHeading:
    "Deine neuen Änderungen werden Funnel Insight unterbrechen. Möchtest du deinen alten Funnel archivieren und die Änderungen in einer neuen Version speichern?",
  openProject: "Projekt öffnen",
  openAdvertisingAccount: "Werbekonto öffnen",
  openInTest: "Im Test-Modus öffnen",
  script: "Skript",
  funnelQA: {
    textOnly: "Nur Text",
    freeText: "Freitextfeld",
    slider: "Schieberegler",
    multi: "Mehrfach ausgewählt",
    single: "Einzeln ausgewählt",
    selectOption: "Eine Option auswählen",
    questionPlaceholder: "Frage hier hinzufügen"
  },
  funnelInsights: {
    leadService: {
      total: "Gesamte Anfragen",
      open: "Offene Anfragen",
      nameWrong: "Falscher Name",
      numberWrong: "Falsche Nummer",
      notReached: "Nicht erreicht Anfragen",
      notSuitable: "Nicht geeignete Anfragen",
      suitable: "Geeignete Anfragen"
    },
    websiteVisits: "Webseitenbesucher",
    phoneVisits: "Anrufversuch Klicks",
    emailVisits: "E-Mail Button Klicks",
    adEval: { totalBudget: "Ausgegebenes Budget für diesen Zeitraum" }
  },
  sync: "Synchronisieren",
  forwardedOnContactCloud: "Weitergeleitet an Contact Cloud",
  forwardedTooltips: {
    success: "Der Lead wurde an den Kunden weitergeleitet",
    pending: "Der Lead befindet sich aktuell noch im Telefonservice",
    failed: "Der Lead ist entweder nicht geeignet oder wurde nicht erreicht"
  },
  multifactor: {
    emailAuth: "E-Mail-Authentifikator",
    appAuth: "App-Authentifikator",
    scan: {
      heading: "Scannen"
    },
    verify: {
      heading: "Code Verifizieren",
      msg: "Um die Einstellung zu ändern, bitte den Authentifizierungscode verifizieren:"
    },
    configure: {
      heading: "Einrichtung von Google Authenticator oder Authy",
      step1:
        "Installiere Google Authenticator (IOS - Android) oder Authy (IOS - Android).",
      step2: `Wähle im Authenticator-App das " + " Symbol.`,
      step3: `Wähle "Einen Barcode (oder QR-Code) scannen" und benutze die Kamera des Handys, um diesen Barcode zu scannen.`
    },
    title: "Mehrfaktorauthentifizierung (MFA)",
    subtitle: "Sichere dein Konto mit TOTP-Zwei-Faktor-Authentifizierung",
    setup: "Einrichtung",
    btnText: "Verifizieren & Aktivieren",
    disableHeading: "Zwei-Faktor-Authentifizierung (2FA) deaktivieren",
    method: "Authenticator-Methode",
    sendCode: "Code senden",
    sendCodeMsg: "Einen Verifizierungscode senden an",
    verificationMsg: `Verifizierungscode wurde gesendet! Wenn du den Code nicht erhalten hast, versuche es in <strong>{{timer}}</strong> Sekunden erneut.`
  },
  common: {
    select: "Auswählen",
    problem: "Problem",
    ignore: "Ignorieren", // Translated by google
    creative: "Kreatives", // Translated by google
    understood: "Verstanden",
    benchmark: "Benchmarck",
    difference: "Difference",
    action: "Action",
    security: "Sicherheit",
    generate: "Erstellen",
    addNew: "Neu hinzufügen",
    user: "Benutzer",
    user_other: "Benutzer",
    logic: "Logik",
    headline: "Überschrift",
    product: "Produkt",
    job: "Stelle",
    enter: "Eingeben",
    import: "Importieren",
    template: "Vorlage",
    template_other: "Vorlagen",
    message: "Nachricht",
    heading: "Heading",
    lead: "Lead",
    lead_other: "Leads",
    funnel: "Funnel",
    funnel_other: "Funnels",
    name: "Name",
    email: "Email",
    syncStatus: "Synchronisierungsstatus",
    update: "Aktualisieren",
    confirmDeletion: "Löschen bestätigen",
    yes: "Ja",
    no: "Nein",
    selectColor: "Farbe auswählen",
    create: "Erstellen",
    source: "Quelle",
    leadSource: "Lead Quelle",
    role: "Rolle",
    firstName: "Vorname",
    lastName: "Nachname",
    city: "Stadt",
    country: "Land",
    new: "Neues",
    old: "Altes",
    changePassword: "Passwort ändern",
    enterEmail: "Email eingeben",
    forgotPassword: "Passwort vergessen",
    send: "Senden",
    login: "Anmelden",
    logout: "Ausloggen",
    alreadyTaken: "Bereits vergeben",
    isAlreadyTaken: "ist bereits vergeben",
    categories: "Kategoriename",
    back: "Zurück",
    save: "Speichern",
    templateName: "Vorlagenname",
    postalCode: "Postleitzahl",
    search: "Suche",
    type: "Typ",
    of: "von",
    results: "Ergebnissen werden angezeigt",
    result: "Ergebnis",
    archive: "Archiv",
    unarchive: "Unacrvhiv",
    customer: "Kunde",
    activeCampaignsOnly: "Nur aktive Kampagnen",
    displayFunnelsWithFbLeadsOnly: "Funnel nur mit Fb-Leads anzeigen",
    customer_other: "Kunden",
    address: "Adresse",
    category: "Kategorie",
    category_other: "Kategorien",
    form: "Formular",
    answer: "Antwort",
    companyInfo: "Unternehmens-Informationen",
    companyName: "Firmenname",
    companyWebsite: "Firmen Webseite",
    websiteBtnTxt: "Text für Website-Button",
    dataProtection: "Datenschutz",
    imprint: "Impressum",
    createCustom: "Benutzerdefiniert erstellen",
    telephone: "Telefon",
    preview: "Vorschau",
    saveFunnel: "Speichern Funnel",
    cancel: "Abbrechen",
    description: "Beschreibung",
    close: "Schließen",
    edit: "Bearbeiten",
    created: "Erstellt",
    on: "am",
    company: "Firma",
    createNew: {
      title: "Neuen {{name}} zuordnen"
    },
    // assignNew: {
    //   title: "Neuen {{name}}"
    // },
    enabled: "Aktiviert",
    openInNewTab: "In neuem tab öffnen",
    sortBy: "Sortieren nach",
    sortOrder: "Reihenfolge sortieren",
    performanceReport: "Leistungsbericht",
    invoices: "Rechnungen",
    deleted: "GELÖSCHT",
    change: "Ändern",
    confirm: "Bestätigen"
  },
  monthsName: {
    jan: "Januar",
    feb: "Februar",
    mar: "März",
    apr: "April",
    may: "Mai",
    jun: "Juni",
    jul: "Juli",
    aug: "August",
    sep: "September",
    oct: "Oktober",
    nov: "November",
    dec: "Dezember"
  },
  missingInfo: {
    leadCount: "Lead-Anzahl",
    funnel: {
      selectCategory: "Kategorie wählen", // Translated by google
      selectCustomer: "Wählen Sie Kunde" // Translated by google
    },
    lead: {
      selectFunnel: "Wählen Sie Trichter" // Translated by google
    }
  },
  advancedFilter: {
    title: "Erweiterte Filter",
    formType: "Formulartyp",
    search: "Suchen",
    archive: "Beinhaltet Archiv",
    userStatus: "Benutzerstatus",
    tags: "Tags",
    status: "Status",
    leadStatus: "Lead Status",
    funnel: "Funnel",
    priceRange: "Ausgegebenes Budget",
    dateRange: {
      today: "Heute",
      yesterday: "Gestern",
      thisWeek: "Diese Woche",
      lastWeek: "Letzte Woche",
      thisMonth: "Dieser Monat",
      lastMonth: "Letzter Monat",
      thisYear: "Dieses Jahr",
      lastYear: "Letztes Jahr",
      last7D: "Letzte 7 Tage",
      last14D: "Letzte 14 Tage",
      last30D: "Letzte 30 Tage",
      last90D: "Letzte 90 Tage",
      custom: "Benutzerdefiniertes Datum",
      days7: "7 Tage",
      days14: "14 Tage",
      days30: "30 Tage"
    },
    fbSort: {
      leadCount: "Lead-Anzahl",
      costPerLead: "Kosten pro Lead",
      click: "Anzahl Klicks",
      reach: "Reichweite",
      impreesions: "Impressionen",
      costPerClick: "Kosten pro Klick",
      spend: "Budget",
      costPerQualified: "Kosten pro qualifiziertem Lead", // Translated by google
      qualifiedCount: "Anzahl qualifizierter Leads", // Translated by google
      leadQualify: "LeadQualität", // Translated by google
      leadCost: "Leadkosten" // Translated by google
    },
    sortOrder: {
      highestFirst: "Höchster zuerst", // Translated by google
      lowestFirst: "Niedrigste zuerst" // Translated by google
    },
    funnelType: "Funnel Typ"
  },
  sidebar: {
    dashboard: "Dashboard",
    templates: "Vorlagen",
    customers: "Kunden",
    categories: "Kategorien",
    gallery: "Gallery",
    tags: "Tags",
    users: "Benutzer",
    settings: "Einstellungen",
    siteSettings: "Seiteneinstellungen", // Translated by google
    profile: "Profil",
    leadAgent: "Lead Agent",
    advertisements: "Werbeanzeigen",
    adOptimization: "Optimierungen"
  },
  funnelTable: {
    all: "Alle",
    leadCount: "Lead Anzahl",
    lastChange: "Letzte Bearbeitung",
    integrationStatus: "Integrationsstatus"
  },
  templateTable: {
    lastModified: "Zuletzt geändert"
  },
  userSearchPlaceholder: "Suche nach Name oder E-Mail",
  searchByNamePlaceholder: "Suche nach Name",
  saveWithoutNewVersion: "Ohne neue Version speichern",
  leadAgent: {
    loadMore: "Mehr laden",
    searchBy: "Nach suchen",
    companyLocation: "Standort des Unternehmens",
    distanceToCustomer: "Entfernung zum Kunden",
    calcDistance: "Entfernung berechnen",
    postalCodeMissingMsg: "Postleitzahl fehlt",
    callInProgress: "Anruf läuft",
    callContact: "Kontakt anrufen",
    calling: "Wird angerufen...",
    sending: "Sendet...",
    completing: "Wird abgeschlossen...",
    complete: "Abgeschlossen",
    wrongNum: "Nummer falsch",
    notReached: "Nicht erreicht",
    wrongName: "Name falsch",
    sendContactToCustomer: "Kontakt an Kunden senden",
    leadInfo: "Lead-Informationen",
    interestedParty: "Interessent",
    enterValue: "Wert eingeben",
    dateOfRegistration: "Registrierungsdatum",
    speedDial: "Kurzwahl",
    availableDaily: "Täglich verfügbar",
    enterComment: "Kommentar eingeben",
    qualified: "Qualifiziert",
    notSuitable: "Nicht geeignet",
    appointmentWitHtheCustomer: "Einen Termin mit dem Kunden vereinbaren",
    appointmentInCalendy: "Einen Termin in Calendy buchen",
    noLeads: "Kein Lead zum Anzeigen",
    already: "Bereits",
    successfullyQualified: "Erfolgreich qualifiziert.",
    notQualifiedYet: "Noch nicht qualifiziert.",
    available: "Verfügbar",
    after: "Nach",
    callAnyway: "Anruf trotzdem",
    interactingWithAnotherAgent: "Interaktion mit ",
    leadServicePlusWarning:
      "Achtung, dieser Lead muss mit dem Leadserice Plus Skript bearbeitet werden",
    notes: "NOTIZEN",
    notesPlaceholder: "Notiz oder Kommentar für den Kunden hinzufügen...",
    emailsToSendHeading: "Email wird gesendet an",
    alreadyTakenMsg: "Dieser Lead wurde von einem anderen Agenten übernommen!",
    allOpenLeadsContactedMsg:
      "Alle offenen Leads, die von Funnel <strong>{{funnelName}}</strong> kontaktiert",
    noLeadsYetTooltip: "Noch keine leads!",
    backToNormalBtn: "Zur normalen Ansicht zurückkehren",
    contactLater: "Lead später kontaktieren",
    contactLaterDialogHeading: "Datum Für Kontaktieren Später"
  },
  leadActivities: {
    title: "Aktivitäten",
    noActivityMsg: "Bisher keine Aktivitäten für diesen Lead!",
    noteTooltip: "Notiz",
    agentInfo: "Agenten-Info",
    // enums
    type: {
      CALL: "Anruf",
      SMS: "SMS",
      EMAIL: "Email",
      RESET: "Zurücksetzen",
      SYNC_MANUALLY: "Manuelle Synchronisierung",
      PARTIAL_SYNC_MANUALLY: "Manuelle Synchronisierung Teilweise"
    },
    resultType: {
      NUMBER_WRONG: "Nummer falsch",
      NAME_WRONG: "Name falsch",
      COMPLETED: "Daten korrekt",
      CALL: "Anruf",
      QUALIFIED: "Lead ist qualifiziert",
      NOT_SUITABLE: "Lead ist nicht geeignet",
      NOT_REACHED: "Nicht erreicht",
      RESET_SUCCESS: "Vom Administrator zurückgesetzt",
      SYNCED_MANUALLY: "Manuell synchronisiert",
      CONTACT_LATER: "Lead später kontaktieren"
    }
  },
  adEvaluation: {
    creativeOverview: "Kreativübersicht",
    leadCount: "Anzahl der Leads",
    leadCost: "Kosten pro Lead",
    budgetSpent: "Budget ausgegeben",
    qualified: "Qualifizierte Leads",
    suggestionGallery: "Vorschlagsgalerie",
    adLabel: "Anzeige",
    adsetLabel: "Anzeigengruppe",
    titlesLabel: "Überschrift",
    descriptionsLabel: "Beschreibung",
    bodiesLabel: "Werbetext",
    expandBtnLabel: "Öffnen"
  },
  funnelsEvaluation: "Kundenbewertung",
  creativeDashboard: "Kreatives Dashboard", // Translated by google
  funnelDetailsOverview: "Übersicht über die Kundendetails",
  invalidIdErrorMessage: "Ungültige ID",
  sendBackToAgentTitle: "Diesen Lead an den Lead Agent zurücksenden",
  sendBackToAgentMessage: `Möchten Sie diesen Lead wirklich zurück zum <strong>Lead agent</strong> verschieben?`,
  tools: "Werkzeuge",
  linkedFunnels: "Verknüpfte Funnels",
  pricePerInvoiceUnitLabel: "Preis pro Rechnungseinheit",
  unitLabel: "Einheit",
  preventReloadWarning:
    "Website verlassen? Deine Änderungen wurden nicht gespeichert!",
  downloadAsCsv: "Herunterladen als CSV",
  suggestionGallery: {
    currentTabLabel: "Aktuelle Vorschläge",
    upload: "Hochladen",
    uploadDialogHeading: "Laden Sie ein neues Element für Vorschläge hochladen",
    uploadImage: "Bild Hochladen",
    dropHere: "HIER ABLEGEN",
    adLink: "Anzeigenlink",
    count: "Anzahl",
    testedLinksButtonText: "Hier wurde das Bild bereits gestet",
    testedLinks: "Testet Links",
    addNewTestedLink: "Neues testet link hinzufügen",
    uploadedSuccessfully: "Hochgeladen Erfolgreich!",
    noteLabel: "Notiz",
    linkToCampaignLabel: "Link zur Kampagne",
    linkToFunnelLabel: "Link zur Funnel",
    archiveModal: {
      heading: "Vorschlags {{what}}",
      msg: `Sind Sie sicher, dass Sie diese Vorschlags <strong class="text-danger">{{what}}</strong> möchten?`,
      multiHeading: "Vorschläge {{what}}",
      multiMsg: `Sind Sie sicher, dass Sie diese Vorschläge <strong class="text-danger">{{what}}</strong> möchten?`
    },
    winningModal: {
      mark: "ansehen",
      remove: "entfernen",
      msg: `Sind Sie sicher, dass Sie diesen Vorschlag als Gewinner {{what}} möchten?`
    }
  },
  leadservicePanel: {
    onHoldHeading: "Offene Anfrage",
    onHoldInfoMsg: "Anzahl Leads in der Wiedervorlage",
    currentlyDisabledWarning:
      "Leadservice dieses Funnel ist derzeit deaktiviert"
  },
  adLinkPlaceholder: "Link zum Werbekonto einfügen",
  uploadScriptDialog: {
    uploadPDF: "Hochladen PDF"
  },
  textElementsTelephoneCheck: {
    switchLabel: "Telefonnummer in 2-Schritten abfragen",
    screenHeadline: "Telefon-Screen Überschrift"
  },
  textElementsImageCheck: {
    switchLabel: "Aktivieren Formular-Bild",
    fieldLabel: "Formular-Bild",
    placementRadioLabel: "Platzierung Formular-Bild",
    radioBeforeLabel: "Vor Formular Subheadline",
    radioAfterLabel: "Nach Formular Subheadline"
  },
  leadTabs: {
    leads: "Leads",
    incompleteLeads: "Unvollständige Leads",
    numberOfEmailsSentLabel: "Anzahl der E-Mail gesendet"
  },
  policySection: {
    switchLabel: "Abschnitt Richtlinie anzeigen",
    toBeShownTooltip: "Der Richtlinienteil wird angezeigt",
    notToBeShownTooltip: "Der Richtlinienteil wird nicht angezeigt",
    textFieldPlaceholder: "Gib den Text zur Anzeige als Richtlinientext ein"
  },
  incompleteLeadDialog: {
    title: "Löschen Unvollständiger Lead",
    msg: "Möchten Sie diese <strong>löschen</strong>?"
  },
  editorModal: {
    deleteConfirmationMsg:
      "Bist Du Dir sicher, dass Du den {{what}} <strong>löschen</strong> möchten?"
  },
  preLandingPage: {
    duplicateLabel: "Dupliziere",
    duplicateSubLabel: "Dupliziere die Pre Lading Page in einen anderen Funnel",
    duplicateSuccessMsg: `Pre Landing Page {{funnelName}} wurde erfolgreich dupliziert`,
    duplicateDisabledTooltip: "Deine Änderungen bist nicht gespeichert!",
    sameFunnelWarning: "Du befindest dich derzeit auf demselben Funnel",
    enableSwitchLabel: "Pre-Landingpage einschalten",
    enableSwitchDialogMsg: `Sind Sie sicher, dass Sie Pre-Landingpage <strong class="text-danger">aktivieren</strong> möchten?`,
    disableSwitchDialogMsg: `Sind Sie sicher, dass Sie Pre-Landingpage <strong class="text-danger">deaktivieren</strong> möchten?`,
    selectTemplateLabel: "Template auswählen",
    templateOneOptLabel: "Vorlage 1 (Bild/Text - Untereinander)",
    templateTwoOptLabel: "Vorlage 2 (Bild/Text - Nebeneinander)",
    deletePointMessage: `Sind Sie sicher, dass Sie diese Point <strong class="text-danger">löschen</strong> möchten?`,
    deletePointSuccess: "Hero Content Point erfolgreich gelöscht.",
    deleteBenefitMessage: `Sind Sie sicher, dass Sie diese Benefit <strong class="text-danger">löschen</strong> möchten?`,
    deleteBenefitSuccess: "Benefit erfolgreich gelöscht."
  },
  preLandingPageOne: {
    headerBgColorLabel: "Hintergrund Farbe des Firmenlogos",
    headlineTextLabel: "Überschrift (ganz oben)",
    headlineColorLabel: "Überschrift Farbe (ganz oben)",
    heroHeadlineTextLabel: "Unterüberschrift (ganz oben)",
    heroHeadlineTextColorLabel: "Unterüberschrift Farbe (ganz oben)",
    heroContentLabel: "Textblock unter dem Bild",
    heroImageLabel: "Grafik der Landingpage",
    ctaButtonTextLabel: "CTA Button Text",
    ctaButtonTextColorLabel: "CTA Text Farbe im Button",
    ctaButtonBackgroundColor: "CTA Button Farbe",
    benefits: {
      benefitsGridHeadline: "Überschrift der Benefits",
      benefitsGridTextColor: "Benefit Text Farbe",
      benefitsGridSubHeadline: "Unterüberschrift der Benefits",
      benefitsGridLabel: "Benefits Auflistung",
      addNewBenefitBtn: "Benefit hinzufügen",
      label: "Überschrift",
      subtext: "Unterüberschrift",
      icon: "Icon",
      selectBtn: "Auswählen"
    },
    footerContent: "Fußbereichs content"
  },
  preLandingPageTwo: {
    backgroundImageLabel: "Hintergrund Grafik",
    centerContentBackgroundColorLabel:
      "Hintergrundfarbe vom Element für die Schriften",
    centerContentBackgroundOpacityLabel: "Hintergrund Element Deckkraft",
    contactUsButtonTextLabel: "Button Text (oben rechts)",
    contactUsButtonColorLabel: "Button Schriftfarbe (oben rechts)",
    contactUsButtonBackgroundHoverLabel: "Button Hover Farbe (oben rechts)",
    phoneNumberHeadlineLabel: "Überschrift Telefonnummer",
    phoneNumberTextLabel: "Telefonnummer",
    phoneNumberColorLabel: "Telefonnummer Schriftfarbe",
    subHeadlineTextLabel: "Unterüberschrift Text",
    subHeadlineColorLabel: "Unterüberschrift Farbe",
    heroContentLabel: "Textfeld (links vom Bild)",
    heroImageLabel: "Hauptbild (Zentral)",
    heroPoints: {
      headingLabel: "Aufzählungspunkte neben dem Bild hinzufügen (Maximal 3)",
      btnLabel: "Aufzählungspunkte neben dem Bild hinzufügen",
      label: "Aufzählungspunkt in Fettschrift (Links)",
      text: "Aufzählungspunkt in normaler Schrift (Rechts)"
    },
    footerMapLabel: "Karte Einbetten bei Google Maps",
    heroContentTextColorLabel: "Schriftfarbe Text links vom Bild",
    heroContentIconColorLabel: "Schriftfarbe Icon links vom Bild"
  },
  versionDialogMsg: {
    changeVersionAndLandingPage:
      "<div>Die Landingpage in dieser Version <strong>unterscheidet</strong> sich von der aktuellen Version.</div><div>Möchtest du fortfahren und die Landingpage <strong>aktualisieren?</strong></div>",
    changeVersionAndDisableLandingPage:
      "<div>In dieser Version ist keine Pre-Landingpage verknüpft.</div><div>Soll die Pre-Landingpage trotzdem <strong>deaktiviert</strong> werden?</div>",
    changeVersionAndEnableLandingPage:
      "<div>In dieser Version ist eine aktive Pre-Landingpage verknüpft.</div><div>Soll die Pre-Landingpage trotzdem <strong>aktiviert</strong> werden?</div>",
    default: `Bist Du Dir sicher, dass Du die Funnel-Version <span class="fw-bold">aktivieren</span> möchten?`
  },
  deleteIntegration: "Integration entfernen",
  disableLeadServiceModal: {
    heading: "Deaktivieren Lead Service",
    associatedIntegrationMsg: `Diese Lead Service ist mit <strong>{{count}} Leads</strong> verknüpft, die noch nicht weitergeleitet wurden`,
    syncNowAndDisable: "Leads direkt weiterleiten und Leadservice entfernen",
    disableNowAndSyncLater:
      "Intergration entfernen (Leads bleiben im Leadservice)"
  },
  lastTaskLabel: "Letzter Task"
};
