import { useTranslation } from "react-i18next";
import Invoice from "./Invoice";
import { ListGroup } from "react-bootstrap";

const MonthlyInvoiceBlock = ({ invoices }) => {
  const { t } = useTranslation();
  const months = [
    ...new Set(
      invoices.map((invoice) => new Date(invoice.invoiceDate).getMonth())
    )
  ];

  const invoicesByMonth = (month) => {
    return invoices.filter(
      (invoice) => new Date(invoice.invoiceDate).getMonth() === month
    );
  };

  const monthNames = [
    t(`monthsName.jan`),
    t(`monthsName.feb`),
    t(`monthsName.mar`),
    t(`monthsName.apr`),
    t(`monthsName.may`),
    t(`monthsName.jun`),
    t(`monthsName.jul`),
    t(`monthsName.aug`),
    t(`monthsName.sep`),
    t(`monthsName.oct`),
    t(`monthsName.nov`),
    t(`monthsName.dec`)
  ];

  const monthStyle = {
    marginTop: "20px"
  };

  return (
    <div>
      {months.map((month) => (
        <div style={monthStyle} key={month}>
          <h4>{monthNames[month]}</h4>
          <ListGroup>
            {invoicesByMonth(month).map((invoice) => (
              <Invoice key={invoice._id} invoice={invoice} />
            ))}
          </ListGroup>
        </div>
      ))}
    </div>
  );
};

export default MonthlyInvoiceBlock;
