import moment from "moment";
import {
  QUESTION_TYPE,
  THUMBNAIL_TYPE,
  DATE_TIME_FORMAT,
  FUNNEL_TYPE_FILTER,
  DATE_REVERSE_FORMAT,
  SYSTEM_HEALTH_ALERT_ISSUE,
  SYSTEM_HEALTH_ALERT_ACTION,
  CREATIVE_ASSET_INSIGHT_TYPE,
  LEAD_AGENT_TIMESTAMP_SESSION_EXPIRY
} from "../components/strings";
import { showError } from "./toast-service";
import { stagesHash } from "../components/leads/deal-stages-hash";
import { updateAsset, getFunnelVersionsById } from "./api-declaration";

const { REACT_APP_STATIC_URL, REACT_APP_ENVIRONMENT, PUBLIC_URL } = process.env;

export const transformOption = (str) => str.toLowerCase();

export const filterTypeOptions = [
  transformOption(FUNNEL_TYPE_FILTER.ALL),
  transformOption(FUNNEL_TYPE_FILTER.LEAD),
  transformOption(FUNNEL_TYPE_FILTER.EMPLOYEE)
];

export const isValidHttpUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return urlPattern.test(urlString);
};

export const colorCodeRegex = /^#(?:[0-9a-f]{3}){1,2}$/i;

export const updateAssetEntry = async (assetData) => {
  return updateAsset(assetData, assetData._id);
};

export const checkStringDataType = (item) => typeof item === "string";

export const generateSiteUrl = (domain, subDomain) => {
  return domain && subDomain
    ? REACT_APP_ENVIRONMENT === "dev"
      ? `${REACT_APP_STATIC_URL}?domain=${domain}&subDomain=${subDomain}`
      : `https://${subDomain}.${domain}`
    : null;
};

export function handleTestMode(url) {
  return url && `${url}${url.includes("?") ? "&" : "?"}testMode=true`;
}

export const handleSelectFieldValue = (selectedValue, options) => {
  if (selectedValue && checkStringDataType(selectedValue)) {
    const entity = options.find((opt) => opt.value === selectedValue);
    return {
      label: entity?.label,
      value: selectedValue
    };
  } else {
    return selectedValue;
  }
};

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return bytes + " " + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
}

export const openColorPicker = (colorPickerRef) => {
  colorPickerRef.current?.click();
};

export const handleColorChange = (() => {
  let timer; // variable persisted here
  return ({ target: { name, value } }, handleValue) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      handleValue(name, value, { shouldDirty: true });
    }, 200);
  };
})();

export const resetAnswers = (resource) =>
  resource.funnelQA.length
    ? resource.funnelQA.map((question) => {
        if (question.type === QUESTION_TYPE.SLIDER) {
          question.answers = [];
        }
        return question;
      })
    : [];

export const generateDateTime = (_date) =>
  !_date ? "---" : moment(_date).format(DATE_TIME_FORMAT);

export const generateDate = (_date) =>
  !_date ? "---" : moment(_date).format(DATE_REVERSE_FORMAT);

export function generateUniqueNumber(length) {
  if (typeof length !== "number" || length <= 0) {
    throw new Error("Invalid length. Length must be a positive number.");
  }
  let result = "";
  const characters = "0123456789";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return parseInt(result, 10);
}

export function getImageDimensions(imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };
    img.onerror = (error) => {
      reject(error);
    };
  });
}

export const getEuroCurrencyData = (data) =>
  data?.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2
  });

export const getFormattedNumber = (data) => data?.toLocaleString("de-DE");

export const getCurrencyData = (currency, data) =>
  data?.toLocaleString("de-DE", {
    style: "currency",
    currency,
    minimumFractionDigits: 2
  });

export const leadAgentSessionExpiry = () =>
  new Date(Date.now() - LEAD_AGENT_TIMESTAMP_SESSION_EXPIRY);

export const removeDuplicatedIds = (question) => {
  delete question._id;
  question.answers.forEach((answer) => {
    delete answer._id;
  });
};

export const generateContactCloudUrl = ({ meta }) => {
  const { tenantId, userId, productId, jobId } = meta;
  const baseUrl = "https://api.contact-cloud.io";
  const webhookUrl = jobId
    ? `application/${tenantId}/${userId}/${jobId}`
    : `${tenantId}/${userId}/${productId}`;
  return `${baseUrl}/${webhookUrl}`;
};

export const generateScriptUrl = ({ workspace, scriptProp, localFileName }) =>
  workspace[scriptProp]?.url && workspace[scriptProp]?.key
    ? `${workspace[scriptProp].url}/${workspace[scriptProp].key}`
    : `${PUBLIC_URL}/agent-scripts/${localFileName}`;

export const removeWhiteSpaces = (_string) => _string.replace(/\s/g, "");

export const generateDealStage = (dealStage) =>
  stagesHash.find((item) => item.value === dealStage);

export const generateAssetFeedSpecs = ({ t, data, insights }) => {
  const { BODY_ASSET, TITLE_ASSET, DESCRIPTION_ASSET } =
    CREATIVE_ASSET_INSIGHT_TYPE;
  return [
    {
      collection: data?.titles || [],
      headerText: t("adEvaluation.titlesLabel"),
      insights: insights?.filter((item) => item.assetType === TITLE_ASSET) || []
    },
    {
      collection: data?.descriptions || [],
      headerText: t("adEvaluation.descriptionsLabel"),
      insights:
        insights?.filter((item) => item.assetType === DESCRIPTION_ASSET) || []
    },
    {
      collection: data?.bodies || [],
      headerText: t("adEvaluation.bodiesLabel"),
      insights: insights?.filter((item) => item.assetType === BODY_ASSET) || []
    }
  ];
};

export const generateSystemActionText = (action) => {
  let generatedText;
  switch (action) {
    case SYSTEM_HEALTH_ALERT_ACTION.FACEBOOK_AD_LEAD_FAILED:
      generatedText = "facebook ad lead creation";
      break;
    default:
      generatedText = "";
  }
  return generatedText;
};

export const generateSystemIssueText = (action) => {
  const { UNKNOWN, INVALID_ACCESS_TOKEN, API_LIMIT_REACHED } =
    SYSTEM_HEALTH_ALERT_ISSUE;
  let generatedText;
  switch (action) {
    case UNKNOWN:
      generatedText = "Unknown";
      break;
    case INVALID_ACCESS_TOKEN:
      generatedText = "Access Token";
      break;
    case API_LIMIT_REACHED:
      generatedText = "API Limit Reached";
      break;
    default:
      generatedText = "";
  }
  return generatedText;
};

export const parseResourceSrc = (resourceObj) =>
  resourceObj?.importUrl
    ? resourceObj.importUrl
    : resourceObj?.key
      ? `${process.env.REACT_APP_ASSET_CDN_URL}/${resourceObj.key}/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`
      : "";

export const parsePreviewResourceSrc = (resourceObj) =>
  resourceObj?.importUrl
    ? resourceObj.importUrl
    : resourceObj?.url && resourceObj?.key
      ? `${resourceObj.url}/${resourceObj.key}`
      : "";

export const fetchAndSetFunnelVersions = async (t, paramsId, setter) => {
  try {
    const response = await getFunnelVersionsById(paramsId);
    const versions = response.data.data;
    versions.currentVersion = versions.allVersions.find(
      ({ _id }) => _id === versions.currentVersion.activeVersion
    );
    setter(versions);
  } catch (error) {
    showError(error.response?.data?.message || t("toastMessage.fetchVersion"));
  }
};
