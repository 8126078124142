import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinnerButton from "../../spinner-button/spinner-button";
import { Button } from "react-bootstrap";

const InvoiceActionsButton = ({
  activityHandler,
  isLoading,
  btnText,
  btnStyle,
  icon,
  btnTextPrimary,
  width,
  variant = "primary",
  className
}) => {
  return (
    <>
      {isLoading ? (
        <SpinnerButton btnText={btnText} btnStyle={btnStyle} width={width} />
      ) : (
        <Button
          variant={variant}
          className={className}
          style={{ width }}
          onClick={activityHandler}
        >
          {icon && <FontAwesomeIcon icon={icon} className="ms-1 me-2" />}
          {btnTextPrimary}
        </Button>
      )}
    </>
  );
};

export default InvoiceActionsButton;
