import { useDispatch } from "react-redux";
import { Accordion } from "react-bootstrap";
import { useParams, useOutletContext } from "react-router";
import { useState, useRef, useCallback, useEffect } from "react";
import Zapier from "./zapier";
import Loader from "../loader/loader";
import ContactCloud from "./contact-cloud";
import {
  setActiveIntegration,
  setActiveIntegrationsCount
} from "../../store/slices/integrationsInfo";
import LeadService from "./lead-service-2.0";
import EmailToCustomer from "./email-to-customer";
import SlackNotifications from "./slack-notifications";
import { showError } from "../../services/toast-service";
import CustomerAppointment from "./customer-appointment";
import AdvertisingAccountLink from "./advertising-account-link";
import CustomAccordionItem from "./integration-view/accordion-item";
import { getIntegrationsByFunnelId } from "../../services/api-declaration";
import { INTEGRATION_NAMES, INTEGRATION_TYPE, SEND_DATA } from "../strings";
import { useTranslation } from "react-i18next";

const FunnelIntegration = () => {
  const { t } = useTranslation();
  const paramsId = useParams().id;
  const dispatch = useDispatch();
  const { funnel } = useOutletContext();
  const [integrations, setIntegrations] = useState({
    zapier: [],
    slack: [],
    email: [],
    contactCloud: []
  });
  const [isIntegrationsLoading, setIsIntegrationsLoading] = useState(true);
  const [accordionActiveKey, setAccordionActiveKey] = useState("0");
  const emailCompRef = useRef(null);
  const contactCloudCompRef = useRef(null);
  const zapierCompRef = useRef(null);

  const getFunnelIntegrations = async () => {
    try {
      setIsIntegrationsLoading(true);
      const response = await getIntegrationsByFunnelId(paramsId);
      const integrationData = {
        zapier: [],
        slack: [],
        contactCloud: [],
        email: []
      };
      response.data.data.forEach((resp) => {
        if (resp.type === INTEGRATION_TYPE.SLACK) {
          integrationData.slack.push(resp);
        } else if (resp.type === INTEGRATION_TYPE.ZAPIER) {
          integrationData.zapier.push(resp);
        } else if (resp.type === INTEGRATION_TYPE.CONTACT_CLOUD) {
          integrationData.contactCloud.push(resp);
        } else if (resp.type === INTEGRATION_TYPE.EMAIL) {
          integrationData.email.push(resp);
        }
      });
      setIntegrations({ ...integrationData });
    } catch (err) {
      showError(
        err.response?.data?.message || "Error in fetching integrations!"
      );
    } finally {
      setIsIntegrationsLoading(false);
    }
  };
  const toggleLeadServiceCallback = ({
    forEmail = false,
    forContactCloud = false,
    forZapier = false,
    checked
  }) => {
    if (forEmail) {
      emailCompRef.current?.handleEmailOnToggleLeadService(checked);
    }
    if (forContactCloud) {
      contactCloudCompRef.current?.handleContactCloudOnToggleLeadService(
        checked
      );
    }
    if (forZapier) {
      zapierCompRef.current?.handleZapierOnToggleLeadService(checked);
    }
  };
  const checkIsActive = ({
    collection = [],
    isStaticVal = false,
    isConfigurableIntegration
  }) => {
    return isStaticVal // for leadservice, leadservicePlus, alternativeAddress and & appointment link
      ? true
      : collection.length
        ? !isConfigurableIntegration
          ? collection.some((item) => item.active)
          : collection.some(
              (item) =>
                item.active && item.sourceData.meta.sendData !== SEND_DATA.NEVER
            )
        : false;
  };

  const getIntegrationsCb = useCallback(getFunnelIntegrations, [paramsId]);
  useEffect(() => {
    if (paramsId) {
      getIntegrationsCb();
    }
  }, [getIntegrationsCb, paramsId]);
  useEffect(() => {
    // set active integrations count
    if (isIntegrationsLoading) return;
    const { zapier, slack, contactCloud, email } = integrations;
    if (zapier.length || slack.length || contactCloud.length || email.length) {
      let count = 0;
      Object.values(integrations).forEach((items) => {
        items.forEach((item) => {
          if (
            item.active &&
            item.sourceData.meta.sendData !== SEND_DATA.NEVER
          ) {
            count++;
          }
        });
      });
      dispatch(setActiveIntegrationsCount(count));
    } else {
      dispatch(setActiveIntegrationsCount(0));
    }
  }, [integrations, isIntegrationsLoading, dispatch]);

  useEffect(() => {
    if (isIntegrationsLoading) return;
    const integrationTemplates = [
      {
        type: INTEGRATION_NAMES.ZAPIER,
        collection: integrations.zapier,
        isStaticVal: false
      },
      {
        type: INTEGRATION_NAMES.SLACK,
        collection: integrations.slack,
        isStaticVal: false
      },
      {
        type: INTEGRATION_NAMES.LEAD_SERVICE,
        collection: [],
        isStaticVal:
          funnel.leadService ||
          funnel.leadServicePlus ||
          funnel.alternativeAddress?.enabled
      },
      {
        type: INTEGRATION_NAMES.EMAIL,
        collection: integrations.email,
        isStaticVal: false
      },
      {
        type: INTEGRATION_NAMES.CONTACT_CLOUD,
        collection: integrations.contactCloud,
        isStaticVal: false
      },
      {
        type: INTEGRATION_NAMES.APPOINTMENT_LINK,
        collection: [],
        isStaticVal: funnel.customerAppointmentLink
      },
      {
        type: INTEGRATION_NAMES.ADVERTISING_ACCOUNT_LINK,
        collection: [],
        isStaticVal: funnel.advertisingAccountLink
      }
    ];
    integrationTemplates.forEach((data) => {
      const isActive = checkIsActive({
        collection: data.collection,
        isStaticVal: data.isStaticVal,
        isConfigurableIntegration: checkIsConfigurableIntegration(data.type)
      });
      dispatch(setActiveIntegration({ collectionName: data.type, isActive }));
    });
  }, [integrations, funnel, isIntegrationsLoading, dispatch]);

  const checkIsConfigurableIntegration = (type) =>
    [
      INTEGRATION_TYPE.ZAPIER,
      INTEGRATION_TYPE.EMAIL,
      INTEGRATION_TYPE.CONTACT_CLOUD
    ].includes(type);

  return isIntegrationsLoading ? (
    <Loader />
  ) : (
    <div className="row m-0">
      <div className="mt-4 px-0">
        <Accordion
          flush
          defaultActiveKey="0"
          activeKey={accordionActiveKey}
          onSelect={(evKey) => setAccordionActiveKey(evKey)}
        >
          <CustomAccordionItem
            evKey="0"
            title="Zapier"
            activeIntegrationKey={INTEGRATION_NAMES.ZAPIER}
          >
            <Zapier
              ref={zapierCompRef}
              zapierIntegrations={integrations.zapier}
              setIntegrations={setIntegrations}
            />
          </CustomAccordionItem>
          <CustomAccordionItem
            evKey="1"
            title="Slack"
            activeIntegrationKey={INTEGRATION_NAMES.SLACK}
          >
            <SlackNotifications
              slackIntegrations={integrations.slack}
              setIntegrations={setIntegrations}
            />
          </CustomAccordionItem>
          <CustomAccordionItem
            evKey="2"
            title="Lead Service 2.0"
            activeIntegrationKey={INTEGRATION_NAMES.LEAD_SERVICE}
          >
            <LeadService
              toggleCb={toggleLeadServiceCallback}
              emailIntegrations={integrations.email}
              zapierIntegrations={integrations.zapier}
              contactCloudIntegrations={integrations.contactCloud}
            />
          </CustomAccordionItem>
          <CustomAccordionItem
            evKey="3"
            title="Email"
            activeIntegrationKey={INTEGRATION_NAMES.EMAIL}
          >
            <EmailToCustomer
              ref={emailCompRef}
              setActivePanel={setAccordionActiveKey}
              emailIntegrations={integrations.email}
              setIntegrations={setIntegrations}
            />
          </CustomAccordionItem>
          <CustomAccordionItem
            evKey="4"
            title="Contact Cloud"
            activeIntegrationKey={INTEGRATION_NAMES.CONTACT_CLOUD}
          >
            <ContactCloud
              ref={contactCloudCompRef}
              contactCloudIntegrations={integrations.contactCloud}
              setIntegrations={setIntegrations}
            />
          </CustomAccordionItem>
          <CustomAccordionItem
            evKey="5"
            title={t("funnelIntegration.appointment.heading")}
            activeIntegrationKey={INTEGRATION_NAMES.APPOINTMENT_LINK}
          >
            <CustomerAppointment />
          </CustomAccordionItem>
          <CustomAccordionItem
            evKey="6"
            title={t("funnelIntegration.advertisingAccount.heading")}
            activeIntegrationKey={INTEGRATION_NAMES.ADVERTISING_ACCOUNT_LINK}
          >
            <AdvertisingAccountLink />
          </CustomAccordionItem>
        </Accordion>
      </div>
    </div>
  );
};
export default FunnelIntegration;
