import {
  Form,
  Button,
  Modal,
  Spinner,
  ModalBody,
  FormSelect
} from "react-bootstrap";
import styled from "styled-components";
import { useParams } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  integrateFunnel,
  getContactCloudJobs,
  getContactCloudUsers,
  getContactCloudProducts
} from "../../services/api-declaration";
import TextField from "../reusable-form-fields/text-field";
import SelectField from "../reusable-form-fields/select-field";
import { showError, showSuccess } from "../../services/toast-service";
import { ContactCloudSetupSchema } from "../../services/validation-schema";
import { SEND_DATA, INTEGRATION_TYPE, FUNNEL_TYPE_FILTER } from "../strings";
import { useTranslation } from "react-i18next";

const ModalLoaderContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const CustomModalBody = styled(ModalBody)`
  min-height: 400px;
`;
const CustomSpinner = styled(Spinner)`
  width: 60px;
  height: 60px;
`;
const SelectBox = styled(FormSelect)`
  font-size: 12px;
`;

const ContactCloudSetupDialog = ({
  show,
  funnel,
  funnelId,
  toggleDialog,
  setIntegrations,
  formData
}) => {
  const { t } = useTranslation();
  const paramsId = useParams().id;
  const [isLoading, setIsLoading] = useState({
    users: false,
    products: false,
    jobs: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [opts, setOpts] = useState({
    users: [],
    products: [],
    jobs: []
  });
  const initialValues = {
    tenantId: funnel?.customer?.contactCloudId || "",
    userId: { label: "", id: "" },
    productId: { label: "", id: "" },
    jobId: { label: "", id: "" },
    for: funnel?.general?.funnelType,
    sendData: ""
  };
  const btnRef = useRef();
  const methods = useForm({
    resolver: yupResolver(ContactCloudSetupSchema),
    defaultValues: initialValues
  });
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = methods;
  const handleLoader = (item, flag) => {
    setIsLoading((values) => {
      const clone = { ...values };
      clone[item] = flag;
      return clone;
    });
  };
  const fetchUsers = async (_tenantId) => {
    try {
      handleLoader("users", true);
      const response = await getContactCloudUsers(_tenantId);
      setOpts((allOptions) => {
        const clone = { ...allOptions };
        clone.users = response.data.data;
        return clone;
      });
    } catch (error) {
      showError(
        t("toastMessage.errorInGetting", {
          what: t("funnelIntegration.contactCloud.optionsError.users")
        })
      );
    } finally {
      handleLoader("users", false);
    }
  };
  const fetchProducts = async (_tenantId) => {
    try {
      handleLoader("products", true);
      const response = await getContactCloudProducts(_tenantId);
      setOpts((allOptions) => {
        const clone = { ...allOptions };
        clone.products = response.data.data;
        return clone;
      });
    } catch (error) {
      showError(
        t("toastMessage.errorInGetting", {
          what: t("funnelIntegration.contactCloud.optionsError.products")
        })
      );
    } finally {
      handleLoader("products", false);
    }
  };
  const fetchJobs = async (_tenantId) => {
    try {
      handleLoader("jobs", true);
      const response = await getContactCloudJobs(_tenantId);
      setOpts((allOptions) => {
        const clone = { ...allOptions };
        clone.jobs = response.data.data;
        return clone;
      });
    } catch (error) {
      showError(
        t("toastMessage.errorInGetting", {
          what: t("funnelIntegration.contactCloud.optionsError.jobs")
        })
      );
    } finally {
      handleLoader("jobs", false);
    }
  };
  const checkOptionsLoader = (loader) => loader === true;
  const handleSave = async (formValues) => {
    try {
      setIsSubmitting(true);
      const body = {
        funnelType: funnel.general.funnelType,
        integrationType: INTEGRATION_TYPE.CONTACT_CLOUD,
        tenantId: formValues.tenantId,
        userId: formValues.userId.id,
        productId: formValues.productId.id,
        jobId: formValues.jobId.id,
        sendData: formValues.sendData,
        active: true
      };
      const response = await integrateFunnel(funnelId, body);
      const metaData = {
        tenantId: formValues.tenantId,
        userId: formValues.userId.id,
        productId: formValues.productId.id,
        jobId: formValues.jobId.id,
        sendData: formValues.sendData
      };
      setIntegrations((data) => {
        const clone = [...data.contactCloud];
        if (clone.length) {
          // for edit
          clone[0].sourceData.meta = metaData;
          data.contactCloud = clone;
          return { ...data };
        } else {
          // for add-new
          return {
            ...data,
            contactCloud: [
              {
                funnelId: paramsId,
                type: INTEGRATION_TYPE.CONTACT_CLOUD,
                sourceData: { meta: metaData },
                description: "",
                active: true,
                _id: response.data.data.upsertedId
              }
            ]
          };
        }
      });
      showSuccess(response.data.message);
      toggleDialog();
    } catch (error) {
      showError(error.response.data.message || t("toastMessage.updateFunnels"));
    } finally {
      setIsSubmitting(false);
    }
  };

  const findOpt = (collection, formProperty) => {
    return collection.find((item) => item.id === formProperty);
  };

  const usersCb = useCallback(fetchUsers, [t]);
  const productsCb = useCallback(fetchProducts, [t]);
  const jobsCb = useCallback(fetchJobs, [t]);
  useEffect(() => {
    if (funnel?.customer?.contactCloudId) {
      usersCb(funnel.customer.contactCloudId);
      if (funnel?.general?.funnelType === FUNNEL_TYPE_FILTER.EMPLOYEE) {
        jobsCb(funnel.customer.contactCloudId);
      } else {
        productsCb(funnel.customer.contactCloudId);
      }
    }
  }, [funnel, usersCb, productsCb, jobsCb]);

  useEffect(() => {
    // for reset initial values in edit mode
    if (!formData) return;
    const {
      sourceData: {
        meta: { userId, productId, jobId }
      }
    } = formData;
    const user = findOpt(opts.users, userId);
    const prod = findOpt(opts.products, productId);
    const job = findOpt(opts.jobs, jobId);
    if (user) {
      setValue("userId", { label: user.label, id: user.id });
    }
    if (prod) {
      setValue("productId", { label: prod.label, id: prod.id });
    }
    if (job) {
      setValue("jobId", { label: job.label, id: job.id });
    }
  }, [formData, opts, setValue]);
  useEffect(() => {
    setValue(
      "sendData",
      funnel?.leadService ? SEND_DATA.AFTER_CONFIRMATION : SEND_DATA.IMMEDIATELY
    );
  }, [setValue, funnel?.leadService]);

  return (
    <Modal show={show} onHide={toggleDialog} backdrop="static" centered>
      <Modal.Header className="bg-primary text-white">
        <h5 className="m-0">
          {t("funnelIntegration.contactCloud.contactCloudSetup")}
        </h5>
      </Modal.Header>
      <CustomModalBody>
        {Object.values(isLoading).some(checkOptionsLoader) ? (
          <ModalLoaderContainer className="position-absolute d-flex align-items-center justify-content-center">
            <CustomSpinner animation="border" />
          </ModalLoaderContainer>
        ) : (
          <FormProvider {...methods} id="domain-form">
            <Form onSubmit={handleSubmit(handleSave)}>
              <TextField
                type="text"
                name="tenantId"
                register={register}
                label={`Tenant: ${funnel?.customer.email}`}
                disabled
              />

              <Form.Group className="mb-3">
                <Form.Label
                  className={`${errors?.userId && "text-danger fw-bold"}`}
                >
                  {t("common.user")}: {errors?.userId && "*"}
                </Form.Label>
                <SelectField
                  control={control}
                  name="userId"
                  options={opts.users}
                  optionLabel="label"
                  optionValue="id"
                  isCapitalize={false}
                />
              </Form.Group>

              {funnel.general.funnelType === FUNNEL_TYPE_FILTER.LEAD ? (
                <Form.Group className="mb-3">
                  <Form.Label
                    className={`${errors?.productId && "text-danger fw-bold"}`}
                  >
                    {t("common.product")}: {errors?.productId && "*"}
                  </Form.Label>
                  <SelectField
                    control={control}
                    name="productId"
                    optionLabel="label"
                    optionValue="id"
                    options={opts.products}
                  />
                </Form.Group>
              ) : funnel.general.funnelType === FUNNEL_TYPE_FILTER.EMPLOYEE ? (
                <Form.Group className="mb-3">
                  <Form.Label
                    className={`${errors?.jobId && "text-danger fw-bold"}`}
                  >
                    {t("common.job")}: {errors?.jobId && "*"}
                  </Form.Label>
                  <SelectField
                    control={control}
                    name="jobId"
                    optionLabel="label"
                    optionValue="id"
                    options={opts.jobs}
                  />
                </Form.Group>
              ) : (
                <></>
              )}

              <Form.Label>{"Select"}:</Form.Label>
              <SelectBox className="mb-3" {...register("sendData")}>
                <option value={SEND_DATA.IMMEDIATELY}>
                  {t("funnelIntegration.selectOptions.immediately")}
                </option>
                <option
                  value={SEND_DATA.AFTER_CONFIRMATION}
                  disabled={!funnel.leadService}
                >
                  {t("funnelIntegration.selectOptions.afterConfirmation")}
                  {!funnel.leadService &&
                    `(${t("funnelIntegration.selectOptions.leadServiceInfo")})`}
                </option>
              </SelectBox>

              <div className="d-flex flex-row-reverse">
                <Button
                  className="ms-2"
                  variant="outline-secondary"
                  onClick={() => toggleDialog()}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  type="submit"
                  ref={btnRef}
                  disabled={isSubmitting}
                  variant="outline-primary"
                >
                  {isSubmitting && (
                    <Spinner className="me-2" animation="border" size="sm" />
                  )}
                  {formData
                    ? t("common.update")
                    : t("funnelIntegration.contactCloud.connectBtn")}
                </Button>
              </div>
            </Form>
          </FormProvider>
        )}
      </CustomModalBody>
    </Modal>
  );
};
export default ContactCloudSetupDialog;
