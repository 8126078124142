import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Button, Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useDialog } from "../../../hooks/useDialog";

const RowActionButtons = ({
  idx,
  item,
  isDeleting,
  isDisabled = false,
  onEditClick,
  onDeleteClick
}) => {
  const { t } = useTranslation();
  const { setDependentStates } = useDialog();

  const handleDeleteClick = (_item, _idx) => {
    setDependentStates({
      selectedIntegration: _item,
      currentIndex: _idx
    });
    onDeleteClick(_item);
  };

  return (
    <>
      <OverlayTrigger
        placement="left"
        overlay={<Tooltip>{t("common.edit")}</Tooltip>}
      >
        <Button
          size="sm"
          className="me-2 rounded-circle"
          variant="outline-secondary"
          onClick={() => onEditClick(item, idx)}
          disabled={isDisabled}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="left"
        overlay={<Tooltip>{t("delete")}</Tooltip>}
      >
        <Button
          size="sm"
          className="rounded-circle"
          variant="outline-danger"
          disabled={isDisabled}
          onClick={() => handleDeleteClick(item, idx)}
        >
          {(item._id && isDeleting?.[item._id]) || isDeleting?.[idx] ? (
            <Spinner size="sm" animation="border" />
          ) : (
            <FontAwesomeIcon icon={faTrashCan} />
          )}
        </Button>
      </OverlayTrigger>
    </>
  );
};
export default RowActionButtons;
