import styled from "styled-components";
import { useEffect, useState } from "react";
import { Collapse, Form, InputGroup } from "react-bootstrap";
import { PRICE_TYPE } from "../strings";
import { CustomDiv } from "./lead-agent-styles";
import { useTranslation } from "react-i18next";

const AgentQuestionDiv = styled.div`
  font-size: 16px;
`;

const AgentPricePrompt = ({ leadFunnel, setCommentPrice }) => {
  const { t } = useTranslation();
  const { FIXED, DYNAMIC, AREA_QUERY } = PRICE_TYPE;
  const initialPriceRange = {
    lowerBound: 0,
    upperBound: 0
  };
  const nameOfAccountingUnit =
    leadFunnel?.nameOfAccountingUnitForLeadAgent || leadFunnel?.category?.unit;
  const forFixedPrice =
    leadFunnel?.leadServicePlusPriceType === FIXED &&
    leadFunnel?.fixedPricePerInvoiceUnitForLeadAgent;
  const forDynamicPriceCalculation =
    leadFunnel?.leadServicePlusPriceType === DYNAMIC &&
    leadFunnel?.pricePerInvoiceUnitForLeadAgent;
  const forAreaQueryPriceCalculation =
    leadFunnel?.leadServicePlusPriceType === AREA_QUERY &&
    leadFunnel?.minBudgetQuery &&
    leadFunnel?.maxBudgetQuery &&
    leadFunnel?.pricePerAreaBelowMinBudget &&
    leadFunnel?.pricePerAreaBetweenBudget &&
    leadFunnel?.pricePerAreaAboveMaxBudget;
  const [priceRange, setPriceRange] = useState(initialPriceRange);

  const getAreaValue = (agentInput) => {
    let areaValue = 0;
    if (agentInput <= leadFunnel.minBudgetQuery) {
      areaValue = leadFunnel.pricePerAreaBelowMinBudget;
    } else if (
      agentInput > leadFunnel.minBudgetQuery &&
      agentInput <= leadFunnel.maxBudgetQuery
    ) {
      areaValue = leadFunnel.pricePerAreaBetweenBudget;
    } else if (agentInput > leadFunnel.maxBudgetQuery) {
      areaValue = leadFunnel.pricePerAreaAboveMaxBudget;
    }
    return areaValue;
  };

  const calculatePriceRange = ({
    value = 0,
    priceType,
    pricePerInvoiceUnit
  }) => {
    const multipliedValue =
      priceType === FIXED
        ? pricePerInvoiceUnit
        : priceType === DYNAMIC
          ? pricePerInvoiceUnit * value
          : getAreaValue(value);
    const _range = multipliedValue * 0.2; // calculate 20%
    const lowerBound = multipliedValue - _range;
    const upperBound = multipliedValue + _range;
    setPriceRange({ lowerBound, upperBound });
    setCommentPrice({ lowerBound, upperBound });
  };

  const handleOnChange = (value) => {
    if (
      value?.length &&
      (forDynamicPriceCalculation || forAreaQueryPriceCalculation)
    ) {
      calculatePriceRange({
        value,
        pricePerInvoiceUnit: leadFunnel.pricePerInvoiceUnitForLeadAgent,
        priceType: forDynamicPriceCalculation
          ? DYNAMIC
          : forAreaQueryPriceCalculation
            ? AREA_QUERY
            : ""
      });
    } else {
      setPriceRange(initialPriceRange);
      setCommentPrice(initialPriceRange);
    }
  };

  useEffect(() => {
    if (forFixedPrice) {
      calculatePriceRange({
        priceType: FIXED,
        pricePerInvoiceUnit: leadFunnel?.fixedPricePerInvoiceUnitForLeadAgent
      });
    }
    // eslint-disable-next-line
  }, [leadFunnel]);

  if (
    !forFixedPrice &&
    !forDynamicPriceCalculation &&
    !forAreaQueryPriceCalculation
  ) {
    return <></>;
  }

  return (
    <CustomDiv className="mt-4">
      {forDynamicPriceCalculation || forAreaQueryPriceCalculation ? (
        <div className="mb-2">
          <Form.Label>{nameOfAccountingUnit}</Form.Label>
          <InputGroup className="w-50">
            <Form.Control
              type="number"
              placeholder={t(
                "funnelIntegration.leadServicePlus.specifyHerePlaceholder"
              )}
              onChange={({ target: { value } }) => handleOnChange(value)}
              onWheel={({ target }) => target.blur()}
            />
          </InputGroup>
        </div>
      ) : (
        <></>
      )}
      <Collapse in={priceRange.lowerBound > 0 && priceRange.upperBound > 0}>
        <div>
          <AgentQuestionDiv className="p-3 border bg-white rounded">
            {`Ok, das, was Sie planen, können wir abbilden. Das würde vom
          Kostenpunkt zwischen ${priceRange.lowerBound}€ und ${priceRange.upperBound}€ liegen. Haben Sie so
          viel eingeplant?`}
          </AgentQuestionDiv>
        </div>
      </Collapse>
    </CustomDiv>
  );
};
export default AgentPricePrompt;
