import { useEffect, useState } from "react";
import { CustomCard, PageHeading } from "../common-styling";
import { useParams } from "react-router";
import { showError } from "../../services/toast-service";
import Loader from "../loader/loader";
import { useTranslation } from "react-i18next";
import InvoiceLists from "./invoice-portal/InvoiceLists";
import { getCustInvoicesYears } from "../../services/api-declaration";

const CustomerInvoices = () => {
  const { t } = useTranslation();
  const [years, setYears] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id: customerId } = useParams();

  useEffect(() => {
    const fetchYears = async () => {
      setIsLoading(true);
      try {
        const response = await getCustInvoicesYears(customerId);
        setYears(response.data.data);
      } catch (error) {
        console.log("Error fetching years:", error);
        showError(error.response?.data?.message || "Failed to fetch years");
      } finally {
        setIsLoading(false);
      }
    };
    fetchYears();
  }, [customerId]);

  return (
    <div className="my-4">
      <CustomCard className="px-4">
        <PageHeading>{t("invoice.heading")}</PageHeading>
        {isLoading ? (
          <Loader />
        ) : years.length > 0 ? (
          <InvoiceLists customerId={customerId} years={years} />
        ) : (
          <div className="mt-5 mb-5 text-center fs-4">
            {t("invoice.noInvoices")}
          </div>
        )}
      </CustomCard>
    </div>
  );
};

export default CustomerInvoices;
